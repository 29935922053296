@import '../../styles/variables.less';

.permission-module {
  .text-center {
    margin-left: 30px;
  }
  .permission-table {
    .ant-table {
      .ant-table-thead {
        th {
          vertical-align: top;
        }
        tr:nth-child(2) {
          background-color: @field-background-color;
          th {
            font-weight: 600;
            color: @primary-color;
          }
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: @white-color;
      }
      .ant-table-tbody > tr.ant-table-row > td {
        background-color: @white-color;
      }
      td:nth-child(2) {
        font-weight: normal;
      }
    }
  }
}
