@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.hours-of-operation-wrapper {
  .ant-card-body {
    padding: 24px;
  }

  .panel-description {
    font-family: @font-family;
    font-size: 12px;
    line-height: 16px;
  }
  .days-checkbox {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
  .panel-content {
    padding-top: 24px;
    padding-bottom: 15px;
    .from-time {
      margin-left: 37px;
    }
    .to-time {
      margin-left: 20px;
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
      background-color: @field-background-color;
    }

    .holiday-collapse {
      .ant-collapse-item > .ant-collapse-header {
        font-size: 12px;
        color: @black-color;
        padding: 16px;
        padding-left: unset;
        font-weight: normal;
        text-transform: capitalize;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          color: @primary-color;
          font-weight: 500;
        }
      }
      &.ant-collapse-borderless
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        background: @white-color;
      }
      &.ant-collapse-borderless > .ant-collapse-item {
        border-bottom: 1px solid @field-background-color;
        &:last-child {
          border-bottom: none;
          .ant-collapse-header {
            padding-bottom: unset;
          }
          .ant-collapse-arrow {
            top: 67%;
          }
        }
      }
      .panel-delete-icon {
        margin-top: 1px;
      }
    }
    .datepicker-component {
      .ant-picker-input {
        .ant-picker-suffix {
          color: @primary-color;
        }
      }
    }
  }
  .add-holiday-btn {
    margin-top: 12px;
    height: 24px;
    box-shadow: 0px 5px 10px rgba(51, 129, 189, 0.25);
    border-radius: 6px;
  }
}

.holiday-modal {
  .common-button {
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    box-shadow: 0px 5px 10px rgba(51, 129, 189, 0.25);
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
    .anticon {
      font-size: 12px;
    }
  }
  .ant-btn-primary[disabled].common-button {
    color: @white-color;
    background: #a3a3ad;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: 800px;
    .datepicker-component {
      .ant-picker-input {
        .ant-picker-suffix {
          color: @primary-color;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-form-item-label {
      margin-left: 9px;
      padding-bottom: 3px;
    }
    .ant-form-item-label > label {
      font-size: 10px;
      color: @text-color;
      font-weight: 400;
      display: inline-flex;
      flex-direction: row-reverse;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: @text-color;
      margin-left: 3px;
    }
    .ant-form-item-has-error {
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: @field-error;
      }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      margin-top: 3px;
      color: @field-error;
      font-size: 10px;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      margin-top: 7px;
      color: @text-color;
      font-size: 10px;
    }
    .save-btn {
      display: flex;
      justify-content: center;
      width: 93px;
      height: 24px;
      float: right;
      margin-top: 10px;
    }
    .preview-section {
      margin-top: 16px;
      .description {
        color: @text-color;
        font-size: 10px;
      }
      .image-section {
        margin-top: 10px;
        position: relative;
        text-align: center;
        border: 1px solid @field-background-color;
        border-radius: 10px;
        img {
          opacity: 0.5;
          width: 100%;
        }

        .message {
          position: absolute;
          top: 42%;
          left: 0;
          width: 100%;
          color: @black-color;
          font-size: 13px;
          word-break: break-all;
        }
      }
    }
  }
}
