@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.onboard-flow {
  align-items: center;
  .ant-result-success .ant-result-icon > .anticon {
    color: @primary-color;
    .ant-result-title {
      font-weight: 600px;
    }
  }

  .react-tel-input .form-control {
    font-family: @font-family;
    width: 100%;
    font-weight: 500;
    padding-left: 1px;
    font-size: @field-font-size;
    line-height: 24px;
    border: 0px solid @signup-background;
    border-radius: @field-border-radius;
    letter-spacing: 0.01rem;
    height: @field-height;
    background: @signup-background;
    color: @text-detail;
    box-shadow: none;
  }

  .react-tel-input .form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  }
  .react-tel-input .flag-dropdown {
    border: none;
    background-color: @signup-background;
  }

  .react-tel-input .selected-flag:hover {
    background-color: unset;
  }
  .react-tel-input .selected-flag:focus {
    background-color: @signup-background;
    border-radius: 10px 0px 0px 10px;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: unset;
  }

  .react-tel-input .flag-dropdown.open {
    background-color: @signup-background;
    border-radius: 10px 0px 0px 10px;
  }

  .react-tel-input .flag-dropdown {
    border-radius: 10px 0px 0px 10px;
  }

  input {
    text-align: center;
  }

  .ant-select-selection-item {
    display: flex;
    justify-content: center;
  }

  .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
  }

  .administrator-form {
    .ant-form-item-explain {
      display: none;
    }
  }

  .business-form {
    .ant-form-item-explain {
      display: none;
    }
  }

  .business-btn {
    margin-top: 9px;
  }

  .onboarding-btn {
    height: 40px;
    font-weight: 500;
    font-size: @field-font-size;
    border-radius: @field-border-radius;
    width: 646px;
    box-shadow: 0px 20px 30px rgba(29, 34, 40, 0.16);
  }
  .ant-btn-primary[disabled].onboarding-btn {
    background-color: @grey-5;
  }

  .card-select {
    border: 1px solid @primary-color;
  }
  .plan-space {
    margin-bottom: 64px;
    .plan-card {
      background: @signup-background;
      text-align: center;
      width: 310px;
      height: 493px;
      border-radius: @field-border-radius;
      cursor: pointer;
      .plan-title {
        font-size: 26px;
        color: #262626;
        font-weight: 600;
      }
      .plan-description {
        color: @description-color;
        font-size: 14px;
        margin-top: 27px;
        padding: 0 35px;
      }
      .price-section {
        margin-top: 27px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .plan-price {
          color: #333333;
          font-weight: 650;
          font-size: @field-font-size;
          margin-top: 2px;
        }
        .text-info {
          margin-top: 2px;
          color: #828282;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .bullet-points {
        margin-top: 27px 0 27px 0;
        padding: 0 40px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #333333;
        font-weight: 600;
        font-size: @field-font-size;
        img {
          margin-right: 12px;
        }
        div:first-child {
          margin-top: 27px;
        }
        div:not(:first-child) {
          margin-top: 15px;
        }
        div {
          display: flex;
          align-items: center;
          span {
            text-align: left;
          }
        }
      }
    }
  }

  .mr-13 {
    margin-right: 13px;
  }

  .mt-5 {
    margin-top: 35px;
  }

  .mb-10 {
    margin-bottom: 25px;
  }
  .business-form {
    .question-icon {
      height: @field-height + 16px;
      font-size: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.process-step {
  padding-top: 72px;
  .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
    height: 34px;
  }
  .ant-steps-item-tail {
    position: absolute;
    top: 28px;
    left: 12px;
    width: 83%;
    padding: 0 10px;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: @text-color;
    font-weight: 500;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: @primary-color;
    font-weight: 500;
  }
  .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-description {
          color: @primary-color;
          font-weight: 500;
        }
      }
    }
  }
  .without-selected-icon {
    font-size: 34px;
    color: @steps-color;
  }

  .selected-icon {
    font-size: 34px;
    color: @primary-color;
    circle {
      fill: @field-background-color;
    }
  }
  .signup-logo {
    width: 60px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .font-size-18 {
    font-size: 18px;
  }
}

.error {
  margin-top: 30px;
  color: @error;
  text-align: center;
}

//Process
.signup-steps-content {
  align-items: center;
  margin-top: 64px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  font-family: @font-family;
  .ant-btn-link {
    span:first-child {
      text-decoration: underline;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .onboard-flow {
    .signup-success-page {
      margin-bottom: 280px;
      .ant-result {
        padding: unset;
        padding-top: 4px;
        padding-bottom: 32px;
        .ant-result-title {
          font-weight: 600;
          color: @header-text-color;
        }
        .ant-result-icon {
          margin-bottom: 28px;
        }
        .ant-result-subtitle {
          color: @description-color;
        }
      }
      span.anticon.anticon-check-circle {
        color: @success-color;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: 45%;
  }
}

/* pricing block*/

.plan-card-wrapper {
  margin-top: 70px;
  .plan-card {
    width: 264px;
    height: 495px;
    border-radius: 6px;
    text-align: center;
    background-color: @signup-background;
    display: flex;
    flex-direction: column;
    .plan-title {
      font-weight: 600;
      font-size: 18px;
      margin-top: 30px;
      color: #262626;
    }
    .plan-description {
      margin-top: 27px;
      font-size: 12px;
      padding: 0 27px;
      color: #828282;
    }

    .footnote {
      margin-top: 27px;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
    }
  }
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

.password-warning {
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.checked-icon {
  color: @primary-color;
  height: 22px;
  width: 22px;
}
