@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.mr-13 {
  margin-right: 13px;
}

.password-warning {
  font-size: 16px;
  line-height: 24px;
}

.checked-icon {
  color: @primary-color;
  height: 22px;
  width: 22px;
}
