@import '../../styles/variables.less';
@import '../../styles/global/variables.less';
.onboarding-steps {
  overflow-x: auto;
  font-family: @font-family;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1199px) {
    overflow-x: hidden;
  }
  .col-height {
    min-height: calc(100vh - 130px);
  }
  .col-hide {
    visibility: hidden;
  }
  .section-distance {
    margin-top: 40px;
    margin-left: 20px;
  }
  .left-margin {
    margin-left: 20px;
  }
  .click-link {
    cursor: pointer;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .click-link:hover {
    text-decoration: underline;
  }
  .icon-distance {
    color: @text-color;
    margin-right: 10px;
    font-size: 22px;
  }
  .profile-section {
    width: 85px;
    margin-left: 40px;
    margin-top: 72px;
    margin-bottom: 17px;
    position: relative;
    .profile-image-hide {
      visibility: hidden;
      width: 85px;
      height: 85px;
    }
    .profile-logo {
      width: 85px;
      height: 85px;
      object-fit: contain;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 0px;
      margin-left: 20px;
    }
  }
  .right-section {
    text-align: right;
    margin-bottom: 27px;
    margin-top: 101px;
    margin-right: 40px;
    @media screen and (max-width: 1199px) {
      margin-top: 0px;
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }
  .steps {
    margin-top: 72px;
    margin-bottom: 64px;
    display: flex;
    align-items: center;
    height: 82px;
    .ant-steps-item-tail {
      top: 23px;
      left: 13px;
      width: 70%;
    }
    @media screen and (max-width: 1199px) {
      margin-left: -18px;
      margin-top: 0px;
    }
  }
  .ant-steps {
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-weight: 500;
    color: @text-color;
    margin-left: 10px;
  }
  .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
    height: 34px;
  }
  .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-description {
          color: @primary-color;
          font-weight: 500;
        }
      }
    }
  }
  .ant-steps-item-icon {
    margin-right: 0px;
  }
  .ant-steps-item-title {
    font-size: 14px;
  }
  .ant-steps-item-content {
    display: inline;
  }
  .steps-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .steps-content-title {
      line-height: 43px;
      font-size: 26px;
      color: @text-detail;
      font-weight: 600;
      margin-bottom: 24px;
    }
    .steps-content-description {
      line-height: 24px;
      color: @field-color;
      width: 646px;
      opacity: 0.65;
      .icon-margin {
        font-size: 12px;
        margin: 0 6px;
        color: @primary-color;
      }
    }
    @media screen and (max-width: 1199px) {
      padding: 0 10px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1320px) {
      margin-left: 200px;
    }
  }
  .add-logo {
    display: flex;
    flex-direction: column;
    text-align: center;
    .logo-description {
      margin-bottom: 5px;
    }
    .upload-logo {
      .description-gray {
        color: @text-color;
      }
      .dragger-font {
        .ant-upload-text {
          color: @primary-color;
          font-weight: 500;
        }
      }
      .ant-upload-list-picture-card
        .ant-upload-list-item:hover
        .ant-upload-list-item-info::before {
        left: 0px;
      }
      .ant-upload-list-picture-card .ant-upload-list-item-info::before {
        left: 0px;
      }
      .anticon-delete {
        color: @error;
      }
      .anticon-delete:hover {
        color: @error;
      }
      width: 600px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 24px;
      .file-types {
        margin-top: 12px;
        margin-bottom: 40px;
      }
      .ant-upload-list {
        position: relative;
        width: 646px;
        height: 100%;
        text-align: center;
        background: #fafafa;
        border: 1px dashed @upload-border-secondary;
        border-radius: 6px;
        cursor: pointer;
        transition: border-color 0.3s;
      }
      .ant-upload-list-picture .ant-upload-list-item,
      .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
      }
      .download-icon {
        color: @primary-color;
        font-size: 23px;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .continue-button {
        margin-top: 24px;
      }
      .ant-upload.ant-upload-drag {
        background-color: @white-color;
      }
    }
    .hide-border {
      .ant-upload-list {
        border: none;
      }
    }
    .uploaded-file {
      .ant-upload-list {
        background-color: @white-color;
      }
      .ant-upload {
        display: none;
      }
      .ant-upload-list-picture-card-container {
        min-width: 200px;
        min-height: 192px;
        margin: 20px;
      }
    }
    .bottom-text {
      position: absolute;
      bottom: 29px;
    }
  }
  .color-palette {
    display: flex;
    flex-direction: column;
    text-align: center;
    .check-color {
      font-size: 13px;
      color: @white-color;
    }
    .color-section {
      position: relative;
    }
    .sketch {
      position: absolute;
      z-index: 2;
      @media screen and (max-width: 1024px) {
        top: 100px;
        right: 100px;
      }
    }
    .second-sketch {
      position: absolute;
      z-index: 2;
      top: 80px;
    }
    .color-list {
      margin-top: 60px;
    }
    .color-list-second {
      position: relative;
      margin-top: 55px;
      margin-bottom: 68px;
    }
    .small-color-list {
      margin-top: 10px;
      margin-bottom: 24px;
    }
    .edit-icon {
      font-size: 23px;
      color: #dadada;
    }
    .color-box {
      cursor: pointer;
      height: 60px;
      margin: 10px;
      width: 60px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .small-color-box {
      cursor: pointer;
      height: 25px;
      margin: 10px;
      width: 25px;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .add-color {
      border: 1.5px solid @black-color;
      .add-icon {
        font-size: 20px;
      }
    }
    .primary-color {
      font-size: 20px;
      font-weight: 600;
      color: @text-detail;
    }
    .color-info {
      display: flex;
      margin-top: 25px;
      width: 646px;
    }

    .preview-div {
      padding: 24px;
      margin: auto;
      width: 646px;
      height: 243px;
      border-radius: 20px;
      border: 1.5px dashed #a3a3a3;
      box-sizing: border-box;
      .preview-blocks {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 38px;
        margin-bottom: 14px;
        background-color: @signup-background;
        border-radius: 10px;
      }
      .preview-link {
        line-height: 38px;
        font-weight: bold;
        text-decoration: underline;
      }
      .positive-msg {
        font-size: 16px;
        font-weight: 500;
        color: @success-color;
      }
      .negative-msg {
        color: #de3838;
        font-size: 16px;
        font-weight: 500;
      }
      .error-block {
        color: #de3838;
        border: 1px solid #de3838;
      }
      .preview-text {
        color: #a3a3a3;
      }
      .preview-columns {
        margin-top: 24px;
      }
    }
    .color-palette-button {
      margin-top: 24px;
    }
    span:last-child {
      font-weight: 400;
    }
  }
  .industry {
    display: flex;
    flex-direction: column;
    text-align: center;
    .industry-block {
      padding: 8px;
      border-radius: 10px;
      min-height: 240px;
      background: @signup-background;
      margin-top: 24px;
      margin-right: 10px;
      width: 230px;
      background-color: @signup-background;
      .ant-divider-horizontal {
        margin: 8px 0;
      }
      .bold-label {
        color: @text-detail;
        font-weight: 600;
      }
      .subarea-group {
        display: flex;
        flex-direction: column;
      }
    }
    .block-parent {
      min-height: 270px;
    }
    .industry-button {
      margin-top: 24px;
    }
    .ant-btn-primary[disabled] {
      background-color: @grey-5;
    }
  }
  .products {
    display: flex;
    flex-direction: column;
    text-align: center;
    .sample-button {
      margin-top: 24px;
      margin-bottom: 227px;
    }
    .steps-margin {
      margin-top: 40px;
      margin-bottom: 27px;
      .ant-steps-item-active {
        .ant-steps-item-title {
          color: @primary-color;
        }
      }
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content {
        line-height: 24px;
        font-size: 16px;
      }
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: @text-color;
      }
      .ant-steps-item-title {
        font-weight: 400;
        font-size: 16px;
      }
      .ant-steps-item-active {
        color: @primary-color;
      }
    }
    .question-button {
      margin-top: 24px;
    }
    .top-space {
      margin-top: 24px;
    }
    .ant-steps {
      max-width: 581px;
      margin-left: auto;
      margin-right: auto;
    }
    .list-items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 203px;
      font-size: 16px;
      font-weight: 500;
      color: @black-color;
      .list-item {
        margin-bottom: 29px;
      }
      .check-products {
        font-size: 21px;
        color: @primary-color;
        margin-right: 11px;
      }
    }
    .list-items:first-child {
      margin-top: 8px;
      margin-left: 6px;
    }
    .second-item {
      margin-left: 23px;
    }
    .list-items:last-child {
      margin-bottom: 32px;
      margin-left: 55px;
    }
  }
  .invite-team {
    width: 600px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .user-list {
      color: @text-detail;
      margin-top: 24px;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
    }
    .email-width {
      width: 360px;
    }
    .email-margin {
      margin-left: 20px;
    }
    .invite-submit {
      width: 100%;
    }
    .space-users {
      display: flex;
    }
    .delete-icon {
      font-size: 18px;
      color: @error;
    }
  }
  .region {
    display: flex;
    flex-direction: column;
    text-align: center;
    input {
      text-align: center;
    }
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
    }

    .ant-select-selection-placeholder {
      display: flex;
      justify-content: center;
    }
    .custom-bottom-margin {
      margin-bottom: 15px;
    }
    .region-button {
      margin: 16px;
      font-family: @font-family;
    }
    .ant-btn-primary[disabled] {
      background-color: @grey-5;
    }
    .checkbox-space {
      margin-bottom: 10px;
    }
    .zip-section {
      width: 646px;
      height: 50px;
      background-color: @signup-background;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin-top: 16px;
      margin-left: 15px;
    }
    .zip-dots {
      color: @black-color;
      font-size: 16px;
      margin-left: 12px;
    }
    .modal-icon {
      font-size: 18px;
      color: @primary-color;
      margin-right: 18px;
      cursor: pointer;
    }
    .no-zip {
      color: #a3a3a3;
      font-size: 16px;
    }
    .place-tag {
      margin-left: 10px;
    }
    .select-width {
      width: 646px;
    }
    .zip-tags {
      margin-left: 5px;
      padding: 8px 12px;
      border-radius: 7px;
      font-size: 16px;
      background-color: @postal-code-bg;
      color: @white-color;
    }
    .slider-width {
      width: 470px;
    }
    .slider-space {
      margin-left: 12px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .space-below {
      margin-bottom: 16px;
    }
    .region-label {
      text-align: left;
      margin-left: 9px;
      margin-bottom: 4px;
      height: 14px;
      font-size: 12px;
      color: @text-color;
      font-weight: 400;
      flex-direction: row-reverse;
      color: @text-color;
      margin-left: 25px;
    }
    .region-input-number {
      border: none;
      border-radius: @field-border-radius;
      color: @field-color;
      font-weight: 500;
      .ant-input-number-input {
        background-color: @field-background-color;
        border-radius: @field-border-radius;
        height: @field-height;
        text-align: center;
      }
    }
    .region-slider-input {
      margin-left: 30px;
      margin-right: 10px;
    }
    .region-step2 {
      margin-bottom: 16px;
      .ant-select-selector {
        background-color: @signup-background;
        border: none;
      }
    }
    .field-width {
      width: 646px;
    }
    .map-component {
      margin-top: 24px;
      margin-bottom: 44px;
    }
    .text-left {
      width: 646px;
      margin-top: 16px;
      text-align: left;
    }
  }
  .success-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    .success-space {
      line-height: 32px;
      margin-bottom: 8px;
    }
    .check-icon {
      font-size: 63px;
      color: @success-color;
      margin-bottom: 28px;
      justify-content: center;
    }
    .continue-button {
      width: 286px;
      font-size: 16px;
      border-radius: 2px;
      border: none;
      height: 40px;
      margin-top: 40px;
      margin-bottom: 21px;
    }
    .cpq-button {
      margin: 0 auto;
      width: 286px;
      font-size: 16px;
      border-radius: 2px;
      border: none;
      height: 40px;
      color: @primary-color;
      background-color: @field-background-color;
    }
  }
  .selected-step {
    color: @primary-color;
    font-weight: 500;
    margin-left: 10px;
  }
  .submit-btn {
    margin: auto;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    width: 646px;
    margin-bottom: 24px;
    .ant-btn-primary[disabled] {
      width: 100%;
    }
  }

  .line-clamp-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .bold-fonts {
    font-weight: 600;
  }

  .mobile-width {
    width: 200px;
  }

  .side-color {
    color: @text-color;
  }

  .user-name {
    position: absolute;
    width: 200px;
    bottom: 0px;
  }

  .radio-button {
    margin-top: 25px;
    .ant-radio-button-wrapper:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .ant-radio-button-wrapper-checked {
      border-radius: 10px;
    }
    .ant-radio-button-wrapper {
      border: none;
      color: @radio-tab-text-color;
      background-color: @radio-tab-color;
    }
    .ant-radio-button-wrapper-checked {
      color: @white-color;
      background-color: @primary-color;
    }
    .ant-radio-button-wrapper:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0px;
      background-color: @field-background-color;
    }
  }

  @keyframes slide-up {
    from {
      width: 60px;
      height: 60px;
    }

    to {
      width: 25px;
      height: 25px;
    }
  }

  .slide-up {
    animation: 2s slide-up;
    -webkit-animation: 2s slide-up;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in {
    animation: 2s fade-in;
    -webkit-animation: 2s fade-in;
  }

  @keyframes move {
    from {
      margin-bottom: 100px;
      opacity: 0;
    }
    to {
      margin-bottom: 29px;
      opacity: 1;
    }
  }

  .slide-up1 {
    animation: 2s move;
    -webkit-animation: 2s move;
  }

  .slide-up2 {
    animation: 3s move;
    -webkit-animation: 3s move;
  }

  .slide-up3 {
    animation: 4s move;
    -webkit-animation: 4s move;
  }

  .without-selected-icon {
    font-size: 34px;
    color: @steps-color;
  }

  .selected-icon {
    font-size: 34px;
    color: @primary-color;
    circle {
      fill: @field-background-color;
    }
  }
  .font-size-18 {
    font-size: 18px;
  }
}

.region-modal {
  .ant-modal.modal .ant-modal-body {
    max-height: 800px;
  }
  .ant-modal.modal .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: @border-radius-lg;
    background: @signup-background;
  }
  .modal-icon {
    font-size: 18px;
    color: @primary-color;
    margin-right: 18px;
    cursor: pointer;
  }
  .plus-icon {
    font-size: 18px;
    color: @primary-color;
    cursor: pointer;
    margin-right: 21px;
  }
  .tags-section {
    max-height: 600px;
    overflow-y: auto;
  }
  .modal-zip-tags {
    height: 40px;
    width: 83px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 8px 12px;
    border-radius: 7px;
    font-size: 16px;
    background-color: @postal-code-bg;
    color: @white-color;
  }
  .region-modal-input {
    width: 88%;
  }
  .close-icon {
    font-size: 10px;
    cursor: pointer;
    margin-left: 3px;
  }
  .region-cta-button {
    background-color: @primary-color;
    color: @white-color;
    font-size: 16px;
    margin: auto;
    width: 646px;
    height: 50px;
    box-shadow: 0px 20px 30px rgba(24, 144, 255, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
  .ant-radio-button:hover {
    color: red;
  }
  input {
    text-align: center;
  }

  .ant-select-selection-item {
    display: flex;
    justify-content: center;
  }

  .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
  }
}

.region-name-tooltip {
  .ant-popover-inner-content {
    width: 300px;
  }
}
