@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.brand-module {
  .preview-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: @text-color;
    margin-left: 10px;
    margin-bottom: 3px;
  }
  .preview-div {
    padding: 16px;
    height: 115px;
    border-radius: 6px;
    border: 1.5px dashed #a3a3a3;
    box-sizing: border-box;
    .preview-blocks {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 165px;
      height: 32px;
      margin-bottom: 14px;
      background-color: @signup-background;
      border-radius: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .preview-link {
      line-height: 38px;
      text-decoration: underline;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .brand-text-left:first-child {
      margin-bottom: 14px;
    }
    .preview-text {
      color: #a3a3a3;
    }
    .preview-columns {
      margin-top: 24px;
    }
  }

  .mt-32 {
    margin-top: 32px;
  }

  .brand-preview-color {
    display: flex;
    .preview-color {
      height: 14px;
      margin-right: 5px;
      width: 14px;
      border-radius: 3px;
    }
  }
}
