.preview-modal {
  .ant-modal.modal .ant-modal-body {
    max-height: 800px;
  }
  .center-image {
    display: flex;
    justify-content: center;
    .preview-image {
      max-width: 700px;
      max-height: 700px;
      object-fit: contain;
    }
  }
}
