@import '../../styles/variables.less';

.product-module {
  .product-item-delete {
    cursor: pointer;
  }
  table {
    .ant-table-placeholder & {
      width: unset;
    }
  }

  .product-item {
    .list-search-box {
      z-index: 999;
      font-weight: 500;
      border: none;
      border-radius: 6px;
      height: 32px;
      width: 100%;
      background-color: @secondary-color-light;
      color: @field-color;
      .ant-input {
        font-size: @field-font-size;
        color: @field-color;
        background-color: @secondary-color-light;
      }
      .ant-input::placeholder {
        color: @placeholder-color;
        font-weight: 400;
      }
      .anticon-search {
        color: #898c94;
      }
    }
    .list-search-box:hover {
      z-index: 999;
    }
    .add-product-table {
      padding: 16px;
      background: @white-color;
    }
    .add-product-title {
      border-bottom: 1px solid @border-color;
      .ant-page-header-heading-title {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .quantity-component {
    width: 50px;
    border: none;
    background-color: @field-background-color;
    color: @field-color;
    margin-right: 5px;
  }
  .quantity-text {
    margin-right: 4px;
  }
  .product-sub-item-table {
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: none;
      font-weight: 500;
    }
    .edit-icon {
      color: @primary-color;
    }
    .check-icon {
      color: #42ae7b;
    }
    .close-icon {
      color: #e05753;
    }
  }
  .no-data-image {
    min-height: calc(100vh - 196px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

  .drag-row {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: none;
  font-weight: 500;
}
.product-item-table {
  .has-scroll-data-search .ant-table .ant-table-body {
    height: calc(100vh - 450px);
  }
  .ant-pagination.mini {
    margin-top: 24px;
    margin-bottom: 19px;
    font-size: 12px;
    .anticon {
      color: #898c94;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: @secondary-color-light;
      font-size: 12px;
      color: #898c94;
      border-radius: 5px;
      border: none;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      background-color: @secondary-color-light;
      color: @table-cell-color;
    }
    .ant-pagination-item {
      border-radius: 5px;
      margin-left: 8px;
      background-color: @secondary-color-light;
      a {
        color: #898c94;
      }
    }
    .ant-pagination-item-active {
      background-color: @primary-color;
      a {
        color: @white-color;
      }
    }
    .ant-select-item .ant-select-item-active {
      background-color: @secondary-color-light;
    }
    .ant-select-item-option-content {
      font-size: 12px;
      font-weight: 500;
    }
    .anticon-right {
      margin-left: 8px;
      margin-right: 20px;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: @secondary-color-light;
    }
  }
}
.add-product-item-button {
  height: 24px;
}
.quantity-component {
  width: 50px;
  border: none;
  background-color: @field-background-color;
  color: @field-color;
  margin-right: 5px;
}
.quantity-text {
  margin-right: 10px;
}
.modal-search {
  height: 30px;
}
.modal-search .ant-input::placeholder {
  color: @text-color;
}

.modal-search .ant-input {
  height: 30px;
}
.highlight-words {
  padding: 0;
  color: @primary-color;
  background-color: unset;
}

.product-ranking-here-btn {
  text-decoration: underline;
  cursor: pointer;
}

.product-tab {
  .common-table {
    tr.ant-table-placeholder {
      height: 175px;
    }
  }
}

.sub-region {
  margin-left: 16px;
}

.suggested-product {
  max-height: 180px;
  overflow-x: auto;
}
