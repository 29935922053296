@import '../../styles/variables.less';

.gallery-component {
  .ant-upload.ant-upload-select-picture-card {
    background: @white-color;
    width: 138px;
    height: 137px;
    border: 1px dashed @upload-border;
  }
  .ant-upload-list-item-info > span {
    font-size: 11px;
    color: @black-color;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 52px;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-uploading.ant-upload-list-item {
    background: @white-color;
    border: 1px dashed @upload-border;
  }

  .ant-upload-list-picture-card-container {
    width: 138px;
    height: 180px;
    margin-right: 16px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    border-radius: 6px;
  }
  div.ant-upload-list-item-thumbnail {
    position: relative;
    top: 15px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 138px;
    height: 137px;
    background: @layout-background;
    border: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    background: @white-color;
  }
  .upload-content {
    display: flex;
    flex-direction: column;
    .upload-title {
      margin-top: 17px;
      font-size: 11px;
      color: @black-color;
    }
    .upload-description {
      margin-top: 3px;
      font-size: 12px;
      color: @upload-description;
    }
    .icon-header {
      font-size: 13px;
      color: @black-color;
    }
  }
  .gallery-label {
    width: 138px;
    text-align: center;
    margin-top: 10px;
    font-size: 11px;
    color: @black-color;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
