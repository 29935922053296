@import '../../../styles/variables.less';

.qrv-filter-button {
  display: flex;
  justify-content: center;
}

.delete-dynamic-field-filter {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pannel-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  bottom: 0px;
  background: @body-background;
  color: @white-color;
  margin: 0 -16px;
  padding: 20px 10px;
  gap: 10px;
  color: @black-color;
  -webkit-box-shadow: 4px -2px 24px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px -2px 24px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 4px -2px 24px -7px rgba(0, 0, 0, 0.2);
  .ant-select.select-width {
    width: 100%;
    min-width: 50%;
  }
  .label {
    color: @black-color;
    font-size: 12px;
    text-align: left;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
}
