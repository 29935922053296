@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.settings-button {
  margin: 0 8px;
}
.try-button {
  &.ant-btn {
    img {
      margin-right: 8px;
      margin-left: 0px;
    }
  }
}
.questionnaire-module {
  .full-height-card {
    .common-table {
      .ant-table-body {
        height: calc(100vh - 320px);
      }
      .ant-table-placeholder {
        height: calc(100vh - 277px);
      }
      .single-item {
        background-color: transparent;
        color: @primary-color;
        border: 1px solid @primary-color;
        font-size: 10px;
        font-weight: 600;
        height: 24px;
        padding: 4px 8px;
        margin: 4px;
        border-radius: 6px;
        .content-text {
          display: flex;
          line-height: 16px;
        }
        .copy-img {
          vertical-align: baseline;
          margin-top: 2px;
        }
      }
    }
  }

  .cpq-padding {
    .ant-card-body:first-child {
      padding: 0px;
    }
  }
  .center-col {
    .center-content {
      height: calc(100vh - 88px);
      overflow-y: auto;
      border-radius: 6px;
      background-color: @white-color;
      padding: 32px 40px;
      overflow-x: hidden;
      max-width: @portal-width;
      margin: 16px auto;
    }

    // added 'position:relative' to center content only for '.dimension-wrapper' because we need to show modal only in preview part (center content)
    .center-content:has(.dimension-wrapper) {
      position: relative;
      .ant-modal-mask,
      .ant-modal-wrap {
        position: absolute;
        top: 80px;
        z-index: 950;
      }
    }

    .custom-form-item {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      .item-label {
        font-size: 16px;
        color: @text-detail-secondary;
      }
      .required-mark {
        display: inline-block;
        font-size: 14px;
        margin-left: 4px;
        line-height: 1;
        font-family: SimSun, sans-serif;
      }

      .custom-tooltip {
        margin: 0 0 3px 4px;
        .anticon {
          cursor: help;
        }
      }
    }
  }
  .right-content {
    height: calc(100vh - 88px);
    padding-right: 8px;
    background-color: #f5f6f9;
    margin: 16px 16px 16px 0px;
    overflow-y: auto;
    border-radius: 6px;

    .page-key-input {
      .ant-input-prefix {
        margin-right: 0px;
        margin-top: 4px;
        color: @primary-color;
        font-weight: 500;
      }
    }
    .page-key-input.input-component {
      .ant-input-affix-wrapper {
        background-color: @field-background-color;
      }
      .ant-input-group-addon {
        background-color: @field-background-color;
      }
      .ant-btn {
        background-color: @field-background-color;
        border: none;
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        border: none;
      }
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
        border-color: @grey-5;
        border-right: 1px solid @grey-5;
        box-shadow: none;
      }
      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: @grey-5;
        border-right: 1px solid @grey-5;
        box-shadow: none;
      }
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        border: 1px solid @field-error;
        background-color: @field-background-color;
      }
    }
    .page-key-input.input-component.ant-input-affix-wrapper-disabled {
      background-color: @field-background-color;
    }
    .page-key-input.input-component.ant-input-affix-wrapper-disabled
      input.ant-input.ant-input-disabled {
      background-color: @field-background-color;
    }
    .mb-20 {
      margin-bottom: 20px;
    }
    .section-header {
      line-height: 13px;
      margin-bottom: 7px;
      .section-title {
        color: @primary-color;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
      }
    }
    .form-hide {
      display: none;
    }
    .min-indent-level {
      margin-top: 16px;
      margin-left: 16px;
    }
    .max-indent-level {
      margin-top: 16px;
      margin-left: 32px;
    }
    .delete-icon {
      font-size: 14px;
      color: @field-error;
    }
    .add-link-btn {
      font-size: 12px;
      &:focus,
      &:active,
      &:hover {
        border-color: #2f3236;
      }
    }

    .new-product-form {
      .pop-up-section {
        margin-bottom: 8px;
        height: 20px;
        padding-bottom: 4px;
        padding-left: 8px;
        box-shadow: inset 0px -1px 0px @field-background-color;
        .pop-up-text {
          font-size: 10px;
          color: #797c86;
          line-height: 16px;
        }
      }
      .bullet-section {
        margin-left: 16px;
        .bullet-text {
          font-size: 10px;
          color: #797c86;
          line-height: 16px;
        }
      }
    }

    .customer-goal-form {
      .bullet-section {
        margin-left: 16px;
        .bullet-text {
          font-size: 10px;
          color: #797c86;
          line-height: 16px;
        }
      }
    }

    .quotes-form {
      .ant-select.select-width {
        min-width: unset;
      }
      .icon-select {
        width: 56px;
        min-width: 56px;
        .ant-select-item {
          font-size: 20px;
          color: @text-detail-secondary;
        }
        .ant-select-selection-item {
          font-size: 20px;
          color: @text-detail-secondary;
        }
      }
      .tab-setting-btn {
        &.ant-btn > span {
          text-decoration: underline;
        }
      }
      .quote-label {
        font-size: 10px;
        color: #797c86;
        line-height: 16px;
      }
    }
    .form-section {
      .questionnaire-collapse {
        .ant-collapse-header {
          padding-bottom: 0;
        }
        .ant-collapse-content {
          font-size: 12px;
          color: @primary-color;
          .ant-collapse-content-box {
            padding-right: 0;
          }
        }
      }
      .widget-drawer {
        height: calc(100vh - 88px);
        position: absolute;
        button.ant-drawer-close {
          padding-left: 0;
        }
        .ant-drawer-content-wrapper {
          width: 100% !important;
          padding: 0;
          .ant-drawer-content {
            background-color: @layout-background;
            .ant-drawer-header {
              padding: 0;
              background-color: @layout-background;
              border-bottom: none;
            }
            .ant-drawer-body {
              padding-right: 8px;
            }
            .ant-drawer-wrapper-body {
              padding-top: 16px;
            }
          }
        }
        .drawer-back {
          width: 102px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          .left-btn {
            font-size: 10px;
            margin-right: 5px;
            line-height: unset;
            height: unset;
          }
        }

        // added !important over here as rc-virtual-list package uses inline css to hide scroll bar in select
        .system-widget-select {
          .rc-virtual-list-holder {
            overflow-y: auto !important;
            transform: none;
          }
        }
      }
    }
  }
  .common-collapse .ant-collapse-item > .ant-collapse-header {
    padding: 8px;
  }
  .common-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 8px 0;
    .ant-form-item {
      margin-bottom: 8px;
    }
  }
  .inside-collapse {
    margin-left: 16px;
    margin-top: 8px;
  }
  .common-checkbox .ant-checkbox + span {
    word-break: break-word;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
}

.setting-modal {
  font-family: @font-family;
  .hide-form {
    display: none;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 150px);
    padding: 0;
    border-radius: 6px 6px 6px 6px;
  }

  .ant-modal-content {
    border-radius: @border-radius-lg;
    background: @signup-background;
  }
  .ant-card-body {
    padding: 24px;
    height: calc(100vh - 311px);
    overflow-y: auto;
  }

  .setting-modal-content {
    .ant-layout-content {
      height: calc(100vh - 264px);
    }
  }

  .ant-card {
    border-radius: 0px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    font-size: 10px;
  }

  .setting-btn {
    background-color: @primary-color;
    color: @white-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0px 20px 30px rgba(24, 144, 255, 0.2);
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
    .anticon {
      font-size: 12px;
    }
  }

  .setting-btn.ant-btn[disabled] {
    background: @field-background-color;
    border: 1px solid @text-color;
    color: @text-color;
    font-size: @h5-font-size;
  }

  .setting-cancel-btn {
    margin-right: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    width: 93px;
    height: 29px;
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
    .anticon {
      font-size: 12px;
    }
  }

  .ant-layout-footer {
    border-top: none;
    display: flex;
    justify-content: flex-end;
  }
  .setting-layout {
    background: #f5f6f9;
    .ant-menu {
      color: #5f6786;
      background: #f5f6f9;
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }

    .ant-menu > .ant-menu-item {
      margin: 10px 0;
    }

    .sider-header {
      background-color: #f5f6f9;
      color: @nav-bar-bg;
      height: 32px;
      width: 256px;
      margin-top: 24px;
      margin-left: 24px;
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    .ant-layout-sider-children {
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: flex-start;
      color: @field-color;
    }

    .ant-menu-submenu-inline {
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: @field-background-color;
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      color: #5f6786;
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background-color: #e8e9ee;
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      color: #505361;
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: #505361;
    }
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background-color: #e8e9ee;
    }
  }
  .content-wrapper {
    .ant-card-bordered {
      border: none;
    }
    .content-header {
      margin: 24px 24px;
    }

    .panel-description {
      font-family: @font-family;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
    }

    .font-10 {
      font-size: 10px;
    }

    .common-collapse .ant-collapse-item > .ant-collapse-header {
      border-bottom: none;
      padding: 12px;
      padding-left: 0;
    }

    .common-collapse.ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 0px 0;
    }

    .common-collapse .ant-collapse-item > .ant-collapse-header {
      color: @text-detail;
      font-family: @font-family;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    .common-collapse.ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 1px solid #d9d9d9;
    }

    .setting-content {
      padding-top: 24px;
      padding-bottom: 15px;
      .brands-data {
        margin-top: 20px;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .brand-select.ant-form-item {
        margin-bottom: 0;
      }

      .input-component .ant-input {
        font-size: 12px;
      }

      .ant-radio-wrapper {
        .industry-label {
          color: #1d2228;
          font-weight: 400;
          font-size: 12px;
        }
        .lob-label {
          color: #5f6786;
          font-weight: 400;
          font-size: 10px;
        }
      }

      span.ant-radio + * {
        font-weight: 400;
        font-size: 12px;
        color: #1d2228;
        margin: 0;
      }

      .ant-radio-inner::after {
        background: none;
      }

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
      }
    }

    .no-data-image {
      &.ant-empty-normal {
        margin: 0;
      }
    }

    .subdomain-prefix {
      color: @black-color;
      display: inline-flex;
      align-items: center;
      margin-left: 10px;
      font-family: @font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;

      .subdomain-input {
        margin-left: 4px;
      }
    }
    .ant-form-item-label {
      margin-left: 9px;
      padding-bottom: 3px;
    }
    .ant-form-item-label > label {
      font-size: 10px;
      color: @text-color;
      font-weight: 400;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: @text-color;
      margin-left: 3px;
    }
    .ant-form-item-has-error {
      .input-component.ant-input-affix-wrapper,
      .input-component.ant-input-affix-wrapper:hover {
        background-color: @field-background-color;
      }
      .input-component.ant-input-affix-wrapper-disabled,
      .input-component.ant-input-affix-wrapper-disabled:hover {
        padding: 9px;
        border: none;
        background-color: @field-background-color;
      }
      .ant-input,
      .ant-form-item-has-error .ant-input-affix-wrapper,
      .ant-form-item-has-error .ant-input:hover,
      .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: @field-background-color;
      }
      .input-component.ant-input-affix-wrapper-focused {
        border: 1px solid @field-error;
        background-color: @field-background-color;
      }
      .input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid @field-error;
        background-color: @field-background-color;
      }

      .ant-input-group {
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
          border: 1px solid @field-error;
        }
      }
    }

    .ant-form-item-has-error
      :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-form-item-has-error
      :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-form-item-has-error
      :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    .ant-form-item-has-error
      :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background-color: @field-background-color;
    }

    .ant-form-item-has-error
      .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: @field-error;
    }

    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      border: none;
    }

    .input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: @field-background-color;
      z-index: 1;
    }

    .ant-input-group-addon {
      border: none;
      padding: 0 4px;
      color: @black-color;
      font-family: @font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      background-color: @white-color;
    }
    .subdomain-input {
      .ant-input {
        color: @field-color;
        font-weight: 500;
        background-color: @field-background-color;
        font-size: 12px;
        line-height: 16px;
      }
      .ant-input::placeholder {
        color: @placeholder-color;
        font-weight: 400;
      }

      .ant-input:placeholder-shown {
        background-color: @field-background-color;
      }

      .ant-input-affix-wrapper {
        border: 1px solid @border-color;
        background-color: @field-background-color;
        border-radius: 6px;
        height: 32px;
        padding: 8px;
        transition: none;
      }

      .ant-input-affix-wrapper-disabled:hover {
        border: none;
        padding: 9px;
      }

      .ant-input-affix-wrapper > input.ant-input {
        background-color: @field-background-color;
      }
      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: @field-background-color;
        z-index: 1;
      }
    }

    .lob-span {
      margin-top: 37px;
    }

    .row-title {
      font-family: @font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: @field-color;
    }
    .row-description {
      font-family: @font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #808080;
      margin-top: 8px;
      padding-bottom: 20px;
    }

    .setting-labels {
      display: flex;
      flex-direction: column;
    }

    .cut-off-form {
      width: 245px;
      height: 50px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .cut-off-time-labels {
        padding: 7px 0px;
        font-family: @font-family;
        color: @text-detail-secondary;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .delete-icon {
    margin-left: 10px;
    font-size: 16px;
    color: @field-error;
  }

  .ant-picker.common-time-picker.appointment-picker {
    width: 100%;
    ::placeholder {
      color: #5f6786;
    }
  }
}

.add-questionnaire-menu {
  svg {
    width: 15px;
    height: 15px;
  }
  span {
    font-weight: @font-weight-normal;
    font-size: @h6-font-size;
  }
  .ant-divider.ant-divider-horizontal {
    margin: 5px 0;
  }
  .menu-title {
    color: @primary-color;
    line-height: 1;
  }
}

.setting-content-heading {
  h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 4px;
    color: #14181c;
  }
}

.block-parent {
  .lob-block {
    margin-right: 10px;
    background-color: @field-background-color;
    border-radius: 10px;
    min-width: 282px;
    .ant-divider-horizontal {
      margin: 0;
    }
    .bold-label {
      color: #1d2228;
      font-weight: 500;
    }
    .lob-section {
      margin: 12px 16px 6px 16px;
      display: flex;
      .ant-checkbox + span {
        white-space: pre-wrap;
      }
    }
    .subarea-radio-section {
      margin: 12px 16px 0px 16px;
      display: flex;
      .ant-checkbox + span {
        white-space: pre-wrap;
      }
    }
    .subarea-section {
      width: 100%;
      display: flex;
    }
    .inline {
      display: inline;
    }
    .lob-label {
      color: #1d2228;
      font-weight: 400;
      font-size: 14px;
    }
    .subarea-label {
      color: #5f6786;
      font-weight: 400;
      font-size: 10px;
    }
  }
}

.secondary-modal {
  .setting-modal-content {
    .ant-layout-content {
      height: calc(100vh - 311px);
    }
  }
}
.add-on-table-select {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: @primary-color;
    font-weight: 500;
    background-color: @white-color;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @field-background-color;
  }
  .ant-select-item {
    font-size: @field-font-size;
    color: @text-detail-secondary;
  }
  .ant-select-dropdown {
    border-radius: @field-border-radius;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  }
  .ant-select-clear {
    background-color: @field-background-color;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border: none;
  }
}

.questionnaire-module {
  .builder-form {
    .switch-logo {
      font-size: 12px;
      line-height: 18px;
      color: @primary-color;
    }

    .required-mark::after {
      display: inline-block;
      margin-left: 4px;
      color: @text-color;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .skip-row {
      .ant-select.select-width {
        min-width: unset;
      }
    }
    .common-checkbox .ant-checkbox + span {
      width: 100%;
    }
    .editable-card {
      .ant-card-head {
        min-height: 32px;
        padding: 0 8px;
      }
      .ant-card-extra {
        padding: 8px 0;
      }
      .ant-card-body {
        padding: 0;
      }
    }

    .common-button {
      &.manage-condition-button {
        color: @primary-color;
        border: 1px solid @primary-color;
        box-shadow: none;
        span {
          margin-left: 5px;
        }
      }
    }
    .card-title {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: @black-color;
      line-height: 21px;
    }
    .select-width.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: @field-background-color;
    }
  }
}

.no-questionnaire {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  text-align: center;
  .title {
    color: @primary-color;
  }
  .description {
    font-size: 15px;
  }
}

.select-all-text {
  font-size: 10px;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: @primary-color;
}

.ant-divider.ant-divider-horizontal.global-divider {
  margin: 11px 0;
}

.ant-divider.ant-divider-horizontal.system-options-divider {
  margin: 4px 0;
}

.error-border.ant-form-item-has-error {
  .ant-picker.ant-picker-status-error.common-time-picker.appointment-picker {
    border: 1px solid @error;
  }
}

.appointment-window {
  max-width: 668px;
}

.questionnaire-collapse.custom-collapse {
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.custom-radio.common-radio {
  span.ant-radio + * {
    margin: 0;
  }
}
.contact-page-wrapper {
  margin-top: 37px;

  .sub-title {
    color: #14181c;
    margin-top: 8px;
    font-size: 14px;
  }

  .contact-divider.ant-divider {
    border-bottom: 1px solid @border-color;
  }
  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: @text-detail;
      font-weight: 500;
    }
  }

  .change-quote-section {
    margin: 24px 0 22px 0;
    .back-link {
      .arrow {
        margin-right: 9px;
      }
      span {
        color: #14181c;
        font-weight: 450;
        font-size: 16px;
        word-break: break-all;
        text-decoration: underline;
      }
      :hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
    .amount-section {
      color: #14181c;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .form-section {
    text-align: center;
    .optional-comment {
      color: @black-color;
      font-weight: 500;
      font-size: 14px;
    }
    .mt-30 {
      margin-top: 30px;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-15 {
      margin-top: 15px;
    }
    .mt-3 {
      margin-top: 3px;
    }
    .common-checkbox .ant-checkbox-inner {
      height: 24px;
      width: 24px;

      &.ant-checkbox-inner {
        top: 3px;
      }
      &.ant-checkbox-inner::after {
        left: 27.5%;
      }
    }
    .common-checkbox .ant-checkbox-checked::after {
      top: 3px;
    }
  }

  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: @text-detail;
      font-weight: 500;
      display: flex;
    }
  }

  .contact-detail-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: unset;
  }
}

.thin-label {
  font-size: 12px;
  color: @text-color;
  font-weight: 400;
  margin-bottom: 4px;
  word-break: break-word;
}

.booleanIcons {
  font-size: 75px;
  svg {
    fill: #1890ff;
  }
}

.skip-page-modal {
  .common-radio span.ant-radio + * {
    font-weight: 400;
    font-size: 12px;
    color: @primary-color;
    margin: 0;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 490px);
    padding: 24px;
    height: 100%;
    border-radius: 6px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
}

.confirm-modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
  }

  .custom-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .content-icon.anticon.anticon-exclamation-circle {
      font-size: 20px;
    }
  }
  .ant-modal-confirm-content {
    display: flex;
  }
}

.dynamic-static-field-checkbox-layer {
  width: calc(100% * (1 / 4) - 10px - 1px);
  padding: 5px 25px;
}

.panel-tree {
  .ant-tree-list-holder-inner {
    flex-direction: row !important; //to override antd inline styling
    flex-wrap: wrap;
  }
  .ant-tree-treenode.ant-tree-treenode-switcher-close {
    max-width: 290px;
    width: 100%;
    @media screen and (max-width: 1440px) {
      max-width: 250px;
    }
    @media screen and (max-width: 768px) {
      max-width: 200px;
    }
  }
  .ant-tree-title {
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;
      display: block;
      margin-bottom: 5px;
      @media screen and (max-width: 768px) {
        max-width: 150px;
      }
    }
  }
}

.label-text-break {
  label {
    flex-wrap: wrap;
    word-break: break-word;
  }
}

.widget-type-tooltip {
  .ant-popover-inner-content {
    width: 360px;
  }
}

.pick-list-draggable-option {
  background: @grey-2;
  border: 1px solid #ccc;
  margin-bottom: 8px;
  padding: 8px;
  z-index: 99999;
}

.pick-list-draggable-option .drag-visible {
  visibility: visible;
}

.dragging-class {
  padding: 0px 0px 10px 0px;
  border: 4px solid transparent;
  border-radius: 4px;
  transition: border 0.3s ease;
}

.subdomain-info.ant-tooltip {
  .ant-tooltip-inner {
    width: 592px;
  }
  .ant-divider-horizontal {
    margin: 12px 0px;
  }
  .sub-domain-tooltip {
    .guidelines-heading {
      margin: 8px 0px;
    }
    ol {
      padding: 0px 21px;
    }
  }
}
