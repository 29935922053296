@import '../../../styles/variables.less';

.product-ranking-panel-component::-webkit-scrollbar {
  display: none;
}

.product-ranking-panel.side-panel-wrapper {
  .side-panel-container & {
    background-color: @white-color;
  }
}

.product-ranking-panel {
  overflow-y: scroll;
  .product-ranking-panel-component {
    padding: 16px;
    height: calc(100vh - 200px);

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    margin: 0;
    .panel-description {
      color: @ada-sidebar-color;
      font-size: 11px;
      font-weight: 500;
    }
    .panel-section {
      max-height: 500px;
      overflow-y: auto;
    }
    .footer-btn-section {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
  }
}
