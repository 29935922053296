@import '../../styles/variables.less';

.datepicker-component {
  &.ant-picker {
    background: @field-background-color;
    width: -webkit-fill-available;
    height: @field-height;
    border: none;
    border-radius: @field-border-radius;
    .ant-picker-input > input {
      color: @field-color;
      font-weight: 500;
      font-size: 12px;
    }
    .ant-picker-input {
      .ant-picker-suffix {
        color: @text-color;
      }
    }
    .ant-picker-input > input::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
  }
  &.ant-picker-focused {
    box-shadow: none;
    border: none;
  }
}

.ant-form-item-has-error {
  .datepicker-component {
    &.ant-picker {
      background: @field-background-color;
      border: 1px solid @field-error;
    }
  }
}

.ant-form-item-has-error
  .datepicker-component.ant-input-number:not([disabled]):hover,
.ant-form-item-has-error
  .datepicker-component.ant-picker:not([disabled]):hover {
  background-color: @field-background-color;
}
