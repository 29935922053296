.filter-form-item {
  .radio-filter-options {
    gap: 5px;
  }
}

.range-container {
  display: flex;
  justify-content: center;
  gap: 12px;

  .dynamic-price-component {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: baseline;
  }
}
