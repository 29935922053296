@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.contact-module {
  .add-customer-btn {
    margin-left: 8px;
    margin-right: 8px;
  }

  .contact-delete-icon {
    cursor: pointer;
  }
}

.contact-creation-modal {
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
    font-weight: 500;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 150px);
  }

  .contact-table {
  }
  .title-section {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    span {
      width: 476px;
      text-align: center;
    }
  }

  .contact-modal-btn {
    background-color: @primary-color;
    color: @white-color;
    height: 24px;
    font-size: 10px;
    font-weight: 500;
    box-shadow: 0px 20px 30px rgba(24, 144, 255, 0.2);
  }

  .contact-cancel-btn {
    background-color: @secondary-color;
    border-color: @secondary-color;
    margin-right: 5px;
    height: 24px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    width: 120px;
    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus {
      background-color: @secondary-color;
      border-color: @secondary-color;
    }
  }
}

.customer-creation-modal {
  .ant-modal-footer {
    border-top: none;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
    font-weight: 500;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 150px);
  }

  .customer-table {
    .has-scroll-data-search .ant-table .ant-table-body {
      height: calc(100vh - 450px);
    }
    .ant-pagination.mini {
      margin-top: 24px;
      margin-bottom: 19px;
      font-size: 12px;
      .anticon {
        color: #898c94;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: @secondary-color-light;
        font-size: 12px;
        color: #898c94;
        border-radius: 5px;
        border: none;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item {
        background-color: @secondary-color-light;
        color: @table-cell-color;
      }
      .ant-pagination-item {
        border-radius: 5px;
        margin-left: 8px;
        background-color: @secondary-color-light;
        a {
          color: #898c94;
        }
      }
      .ant-pagination-item-active {
        background-color: @primary-color;
        a {
          color: @white-color;
        }
      }
      .ant-select-item .ant-select-item-active {
        background-color: @secondary-color-light;
      }
      .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 500;
      }
      .anticon-right {
        margin-left: 8px;
        margin-right: 20px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @secondary-color-light;
      }
    }
  }

  .customer-search-box {
    font-weight: 500;
    border: none;
    border-radius: 6px;
    height: 24px;
    width: 100%;
    background-color: @secondary-color-light;
    color: @text-detail;
    .ant-input {
      font-size: 10px;
      color: @text-detail;
      background-color: @secondary-color-light;
    }
    .ant-input::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
    .anticon-search {
      color: #898c94;
    }
  }

  .add-customer-btn {
    height: 24px;
    font-size: 10px;
  }
  .cancel-customer-btn {
    height: 24px;
    background-color: @secondary-color;
    border-color: @secondary-color;
    margin-right: 5px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    width: 120px;
    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus {
      background-color: @secondary-color;
      border-color: @secondary-color;
    }
  }

  .modal-search {
    height: 32px;
  }
  .modal-search .ant-input::placeholder {
    color: @text-color;
  }
}

.property-creation-modal {
  .ant-modal-footer {
    border-top: none;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
    font-weight: 500;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 150px);
  }

  .property-table {
    .has-scroll-data-search .ant-table .ant-table-body {
      height: calc(100vh - 450px);
    }
    .ant-pagination.mini {
      margin-top: 24px;
      margin-bottom: 19px;
      font-size: 12px;
      .anticon {
        color: #898c94;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: @secondary-color-light;
        font-size: 12px;
        color: #898c94;
        border-radius: 5px;
        border: none;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item {
        background-color: @secondary-color-light;
        color: @table-cell-color;
      }
      .ant-pagination-item {
        border-radius: 5px;
        margin-left: 8px;
        background-color: @secondary-color-light;
        a {
          color: #898c94;
        }
      }
      .ant-pagination-item-active {
        background-color: @primary-color;
        a {
          color: @white-color;
        }
      }
      .ant-select-item .ant-select-item-active {
        background-color: @secondary-color-light;
      }
      .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 500;
      }
      .anticon-right {
        margin-left: 8px;
        margin-right: 20px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @secondary-color-light;
      }
    }
  }

  .property-search-box {
    font-weight: 500;
    border: none;
    border-radius: 6px;
    height: 24px;
    width: 100%;
    background-color: @secondary-color-light;
    color: @text-detail;
    .ant-input {
      font-size: 10px;
      color: @text-detail;
      background-color: @secondary-color-light;
    }
    .ant-input::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
    .anticon-search {
      color: #898c94;
    }
  }

  .add-property-btn {
    height: 24px;
    font-size: 10px;
  }
  .cancel-property-btn {
    height: 24px;
    background-color: @secondary-color;
    border-color: @secondary-color;
    margin-right: 5px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    width: 120px;
    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus {
      background-color: @secondary-color;
      border-color: @secondary-color;
    }
  }

  .modal-search {
    height: 32px;
  }
  .modal-search .ant-input::placeholder {
    color: @text-color;
  }
}
