@import '../../styles/variables.less';

.product-pricing-module {
  .product-pricing-table {
    .ant-table .ant-table-thead .region-bg-color:hover {
      background-color: @field-background-color;
    }
    .first-column {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-size: 12px;
      color: @text-color;
      font-weight: 600;
      .availability-text {
        line-height: 18px;
        margin-top: 16px;
        margin-bottom: 39px;
      }
      .base-cost-text {
        line-height: 18px;
        margin-bottom: 17px;
      }
      .default-price-text {
        line-height: 18px;
        margin-bottom: 34px;
      }
      .margin-target-text {
        line-height: 18px;
        margin-bottom: 29px;
      }
    }
    .second-column {
      display: flex;
      flex-direction: column;
      .product-name {
        margin-top: 16px;
        color: @text-detail-secondary;
        font-weight: 500;
      }
      .product-details {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        margin-top: 4px;
        margin-bottom: 15px;
        .product-details-manufacturer {
          margin-right: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .base-cost {
      margin-bottom: 8px;
      height: 27px;
      font-size: 12px;
    }
    .default-price {
      margin-bottom: 25px;
      height: 27px;
      font-size: 12px;
    }
    .profit-margin {
      margin-bottom: 24px;
      height: 27px;
      font-size: 12px;
    }
    .card-section {
      display: flex;
      background-color: @product-pricing-card;
      border-radius: 0px;
      height: fit-content;
      padding-bottom: 15px;
      .region-card {
        width: 121px;
        padding: 0 16px;
      }
      .sub-region-card {
        width: 121px;
        padding: 0 16px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: #e5e7ea;
      }
      .not-available-card {
        opacity: 0.3;
      }
      .available-section {
        margin-top: 16px;
        margin-bottom: 35px;
      }
      .profit-section {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 14px;
        .profit-text {
          font-size: 11px;
          line-height: 15px;
          margin-bottom: 2px;
        }
        .amount {
          font-size: 13px;
          line-height: 16px;
          font-weight: 600;
        }
        .red-amount {
          color: #be3636;
        }
        .green-amount {
          color: #3a760b;
        }
      }
      .region-input {
        font-size: 12px;
        background-color: @white-color;
      }
    }
    .region-bg-color {
      background-color: @product-pricing-card;
    }
    .no-horizontal-padding {
      padding-left: 0;
      padding-right: 0;
    }
    .sub-region-right-padding {
      padding: 0;
      padding-right: 8px;
    }
    .padding-left {
      padding-left: 16px;
    }
    .region-title {
      text-align: center;
    }
    .sub-region-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: #e5e7ea;
      padding: 0;
      padding-left: 8px;
      height: 42px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      .sub-region-text {
        font-size: 9px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
}

.cost-update-modal {
  .info-tooltip {
    .ant-tooltip-content {
      color: @white-color;
    }
  }
  .error-text {
    display: flex;
    justify-content: center;
    color: @error;
    font-size: 10px;
  }
  .cost-section {
    font-weight: 400;
    width: 80px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .h-42 {
    height: 42px;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger).apply-btn {
    margin-left: 2px;
  }
  .apply-btn {
    margin-left: 2px;
  }
  .input-component.ant-input-affix-wrapper {
    height: 27px;
    font-size: 12px;
  }
  .ant-form-item {
    margin: 0px;
    width: 100%;
  }
  .ant-form-item-control-input {
    min-height: 27px;
  }
  .ant-form-item-explain-error {
    margin-top: 2px;
    font-size: 10px;
    line-height: 10px;
    min-height: 10px;
  }
  .ant-input-group.ant-input-group-compact {
    display: flex;
    align-items: baseline;
  }
  .profit-select-width {
    width: 50px;
    min-width: unset;
  }
  .cost-modal-label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: @text-color;
  }
  .product-name {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: @text-detail-secondary;
  }
  .product-second-row {
    font-size: 11px;
    line-height: 15px;
    color: @text-detail-secondary;
    opacity: 0.5;
  }
  .lock-btn {
    background-color: #e9ebf2;
    border: none;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 20px;
    height: 20px;
  }
  .input-title {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: @text-detail-secondary;
    margin-bottom: 8px;
  }
  .input-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: @text-detail;
    .arrow-icon {
      margin: 0 16px;
      color: @text-color;
    }
  }
  .divider {
    margin: 16px 0;
    border-top: 1px solid #e5e5e5;
  }
  .tree-section {
    display: flex;
    justify-content: space-between;
  }
  .select-all-text {
    font-size: 10px;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: @primary-color;
  }
  .product-pricing-panel-tree {
    font-size: 10px;
    color: @text-color;
    font-weight: 600;
    .ant-tree-checkbox + span {
      padding: 0;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      font-family: 'Poppins';
      color: @text-detail;
    }
    .parent-width {
      width: 120px;
    }
    .child-width {
      width: 95px;
    }
    .title-max-width {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ant-tree-checkbox-inner::after {
      left: 26%;
    }
    .ant-tree-checkbox-indeterminate {
      .ant-tree-checkbox-inner::after {
        left: 50%;
      }
    }

    .ant-tree-checkbox-inner {
      height: 16px;
      width: 16px;
      border: none;
      background-color: @secondary-color-light;
      border: none;
    }
    .ant-tree-checkbox-disabled.ant-checkbox-checked .ant-tree-checkbox-inner {
      background-color: #f5f5f5;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: @primary-color;
    }
    .ant-tree-switcher {
      display: none;
    }
    .ant-tree-switcher-icon {
      vertical-align: auto;
    }
  }
}

.unavailable-mark-tooltip {
  .ant-popover-inner-content {
    width: 250px;
    max-width: max-content;
    word-break: break-word;

    .line-height {
      line-height: 1.5;
    }
  }
}
