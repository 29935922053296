@import '../../styles/variables.less';

.property-module {
  .item-delete {
    cursor: pointer;
  }
  .tab-table {
    .ant-table td:nth-child(1) {
      font-weight: 500;
    }
    .ant-table td:nth-child(2) {
      font-weight: unset;
    }
  }
}

.property-modal {
  .property-table {
    .extra-text {
      text-align: center;
      font-weight: 600;
    }
  }
}

.add-items-modal {
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
    font-weight: 500;
  }
  .ant-modal.modal .ant-modal-body {
    max-height: calc(100vh - 150px);
  }
  .ant-modal-footer {
    border-top: unset;
  }
  .item-table {
    margin-top: 10px;
    .has-scroll-data-search .ant-table .ant-table-body {
      height: calc(100vh - 450px);
    }
    .ant-pagination.mini {
      margin-top: 24px;
      margin-bottom: 19px;
      font-size: 12px;
      .anticon {
        color: #898c94;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: @secondary-color-light;
        font-size: 12px;
        color: #898c94;
        border-radius: 5px;
        border: none;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item {
        background-color: @secondary-color-light;
        color: @table-cell-color;
      }
      .ant-pagination-item {
        border-radius: 5px;
        margin-left: 8px;
        background-color: @secondary-color-light;
        a {
          color: #898c94;
        }
      }
      .ant-pagination-item-active {
        background-color: @primary-color;
        a {
          color: @white-color;
        }
      }
      .ant-select-item .ant-select-item-active {
        background-color: @secondary-color-light;
      }
      .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 500;
      }
      .anticon-right {
        margin-left: 8px;
        margin-right: 20px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @secondary-color-light;
      }
    }
  }
  .add-item-button {
    height: 24px;
  }
  .quantity-component {
    width: 50px;
    border: none;
    background-color: @field-background-color;
    color: @field-color;
    margin-right: 5px;
  }
  .list-search-box {
    font-weight: 500;
    border: none;
    border-radius: 6px;
    height: 24px;
    width: 100%;
    background-color: @secondary-color-light;
    color: @field-color;
    .ant-input {
      font-size: @field-font-size;
      color: @field-color;
      background-color: @secondary-color-light;
    }
    .ant-input::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
    .anticon-search {
      color: #898c94;
    }
  }
  .quantity-text {
    margin-right: 10px;
  }
  .modal-search {
    height: 32px;
  }
  .modal-search .ant-input::placeholder {
    color: @text-color;
  }

  .modal-search .ant-input {
    height: 32px;
  }
  .highlight-words {
    padding: 0;
    color: @primary-color;
    background-color: unset;
  }
}
