@import '../../../styles/variables.less';

.product-item-detail {
  .ant-upload.ant-upload-select-picture-card {
    background: @white-color;
    width: 138px;
    height: 137px;
    border: 1px dashed @upload-border;
  }
  .ant-upload-list-item-info > span {
    font-size: 8rem;
    color: @primary-color;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 52px;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-uploading.ant-upload-list-item {
    background: @white-color;
    border: 1px dashed @upload-border;
  }

  .ant-upload-list-picture-card-container {
    width: 138px;
    height: 180px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    border-radius: 6px;
  }
  div.ant-upload-list-item-thumbnail {
    position: relative;
    top: 15px;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item-info
    .ant-upload-span.ant-upload-list-item-thumbnail
    .ant-upload-list-item-file
    .anticon-file {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 138px;
    height: 137px;
    background: @layout-background;
    border: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    background: @white-color;
  }

  .ant-upload-list-picture-card-container {
    margin-bottom: 0;
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    .upload-title {
      margin-top: 17px;
      font-size: 11px;
      color: @black-color;
    }
    .upload-description {
      margin-top: 3px;
      font-size: 12px;
      color: @upload-description;
    }
    .icon-header {
      font-size: 13px;
      color: @black-color;
    }
  }

  .file-type {
    width: 117px;
    height: 95px;
    margin: 22px 2px;
    background-color: @white-color;
  }
  .render-upload-label {
    width: 138px;
    margin-top: 10px;
    font-size: 11px;
    color: @black-color;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
