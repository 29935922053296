@import '../../styles/variables.less';

.common-tree {
  &.ant-tree {
    font-size: @field-font-size;
    color: @field-color;
    font-weight: 500;
    .ant-tree-treenode.ant-tree-treenode-switcher-close {
      height: (@field-height / 2);
    }
    .ant-tree-checkbox + span {
      padding: 0 10px;
      color: @field-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 150px;
    }
    span.ant-tree-checkbox-inner {
      height: @checkbox-size;
      width: @checkbox-size;
      border-radius: @checkbox-border-radius;
      border: 1.5px solid @field-color;
    }
  }
}
