@import '../../styles/variables.less';
.region-module {
  #inner-tabs {
    .ant-tabs-tab {
      padding: 12px 0 0;
      font-size: 12px;
      font-weight: 400;
      color: @table-cell-color;
    }
    .ant-tabs-tab-active {
      font-weight: 500;
    }
    .ant-tabs-tab-btn {
      margin-bottom: 10px;
    }
    .ant-tabs-content {
      height: unset;
      overflow: hidden;
    }
    .ant-tabs-content > .ant-tabs-tabpane {
      padding: 16px 0 5px;
    }
  }
  .slider-width {
    margin: 10px 10px 10px;
  }
  .slider-number {
    border: none;
    background-color: @field-background-color;
    color: @field-color;
  }
  .state-count {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    .steps-content-description {
      font-size: 14px;
      .icon-margin {
        font-size: 12px;
        margin: 0 6px;
        color: @primary-color;
      }
    }
  }
  .state-count-step2 {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    .steps-content-description {
      font-size: 14px;
      .icon-margin {
        font-size: 12px;
        margin: 0 6px;
        color: @primary-color;
      }
    }
  }
  .state-list {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .postal-error {
    font-size: 10px;
    color: @field-error;
    margin-left: 15px;
  }
  .location-dropdown {
    margin-top: 3px;
  }
  .zip-tags {
    margin-left: 5px;
    padding: 3px 8px;
    border-radius: 7px;
    font-size: 12px;
    background-color: @postal-code-bg;
    color: @white-color;
  }
  .modal-icon {
    margin-right: 18px;
    cursor: pointer;
    font-size: 18px;
    color: @primary-color;
  }
  .zip-section {
    height: 32px;
    background-color: @field-background-color;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }
  .postal-margin {
    margin-bottom: 3px;
  }
  .address-col {
    margin-top: 16px;
    .slider-number {
      margin-left: 16px;
      margin-right: 10px;
      width: 40%;
    }
    .slider-text {
      margin-right: 16px;
      font-size: 10px;
      color: @text-color;
      font-weight: 400;
    }
    .add-button {
      font-size: 12px;
      font-weight: 500;
      height: 32px;
      border-radius: 6px;
      width: 100%;
      .ant-btn-primary[disabled] {
        width: 100%;
      }
    }
  }
  .region-label {
    margin-left: 9px;
    font-size: 10px;
    color: @table-header-color;
    font-weight: 400;
  }
  .state-add-button {
    margin-top: 18px;
  }
  .auto-complete-space {
    margin-top: 3px;
    padding: 0 2px;
  }
  .select-width {
    padding: 0 2px;
    width: 100%;
  }
  .region-checkbox {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
}
