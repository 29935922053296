@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.tenant-setting-wrapper {
  .ant-card-body {
    padding: 24px;
  }

  .react-tel-input .form-control {
    width: 100%;
    font-size: 12px;
    color: @field-color;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid @field-background-color;
    letter-spacing: 0.01rem;
    height: 32px;
    background: @field-background-color;
  }

  .setting-form-label {
    color: @text-color;
    font-size: 10px;
  }
  .ml-6 {
    margin-left: 6px;
  }

  .copy-icon {
    cursor: pointer;
  }
  .lob-section {
    display: flex;
    flex-direction: column;
    margin: 24px;

    .lob-title {
      color: @field-color;
      font-weight: 600;
      font-size: 12px;
    }
    .lob-description {
      color: #808080;
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .react-tel-input {
    .custom-dropdown {
      background-color: @field-background-color;
      border: none;
    }
    .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
      background-color: @field-background-color;
    }
    .flag-dropdown.open .selected-flag {
      background-color: @field-background-color;
      border: none;
    }
    .selected-flag:focus {
      background-color: @field-background-color;
      border: none;
    }
  }

  .date-format {
    display: flex;
    .format-example {
      margin-left: 15px;
    }
  }
  .date-format-preview {
    display: flex;
    font-size: 15px;
    width: 100%;
    flex-direction: row;
    .ant-form-text {
      color: @field-color;
      margin-left: 5px;
    }
  }
}
