@import '../../styles/variables.less';

.product-ranking-module {
  .product-ranking-listing {
    height: calc(100vh - 104px);
    overflow-y: auto;
    background-color: @white-color;
    padding-top: 8px;
    .ant-table .ant-table-placeholder {
      height: calc(100vh - 820px);
    }

    .quantity-component {
      width: 80px;
      border: none;
      background-color: @field-background-color;
      color: @field-color;
      margin-right: 5px;
    }
    .edit-icon {
      color: @primary-color;
    }
    .check-icon {
      color: @success-color;
    }
    .close-icon {
      color: @error;
    }
  }
  .drag-row {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .highlight {
    color: @primary-color;
  }
}
.label-with-tooltip-panel {
  .ant-row.ant-form-item-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: baseline;
  }
  .ant-form-item-label > label {
    font-size: 16px;
  }
  .ant-form-item-label > label::after {
    content: none;
  }
}
