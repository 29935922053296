@import './variables.less';

.tile-grid {
  &.mt-35 {
    margin-top: 35px;
  }
  .card-selected {
    background-color: @primary-color;
    & .card-title,
    .card-description {
      color: @white-color;
    }
  }

  .center-aligned {
    border: none;
    .ant-card-body {
      justify-content: center;
    }
  }

  & .custom-form-item-label {
    .ant-form-item-label > label {
      font-size: 16px;
    }
  }

  .card-selected.ant-card-grid-hoverable:hover {
    background-color: @primary-color;
  }
  .grid-card {
    cursor: pointer;
    padding: 16px;
  }

  .cpq-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 450;
    text-align: center;
    color: @title-color;
    margin-bottom: 4px;
  }
  .card-image {
    object-fit: contain;
    border-radius: 12px;
    max-width: 100%;
  }
  .new-product-title {
    text-align: center;
    color: @field-color;
    font-weight: 600;
    font-size: 28px;
    margin: 0 10px;
    word-break: break-word;
    margin-bottom: 50px;
  }

  .tile-view {
    width: 50%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .grid-card:first-child {
      border-top-left-radius: 6px;
    }
    .grid-card:nth-child(2) {
      border-top-right-radius: 6px;
    }
    .grid-card:last-child:nth-child(odd) {
      border-bottom-left-radius: 6px;
    }
    .grid-card:last-child:nth-child(even) {
      border-bottom-right-radius: 6px;
    }
    .grid-card:nth-last-child(2):nth-child(odd) {
      border-bottom-left-radius: 6px;
    }
  }

  .list-view {
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-title {
    font-size: 14px;
    font-weight: 500;
    color: @black-color;
    line-height: 21px;
    min-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 270px;
  }

  .card-description {
    font-size: 14px;
    font-weight: 450;
    color: @field-placeholder;
    line-height: 21px;
    word-break: break-word;
  }

  .zoom-image {
    font-size: 14px;
    font-weight: 450;
    line-height: 21px;
  }
  .color-white {
    color: @white-color;
  }
  .color-primary {
    color: @primary-color;
  }

  .back-section {
    text-align: center;
    margin-top: 50px;
  }

  .back-btn {
    color: @title-color;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #e6e9f1;
  }
  .font-75 {
    font-size: 75px;
  }
}

.preview-modal {
  .modal-title {
    text-align: center;
    color: @black-color;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
  }
  .modal-image {
    margin: 16px 0;
    height: calc(100vh - 580px);
    object-fit: contain;
    border-radius: 10px;
    max-width: 100%;
  }
}
