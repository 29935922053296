@import '../../../previewComponents/globalComponents/styles/variables.less';

.support-page-wrapper {
  .editor-render :is(body, div, h1, h2, h3, h4, h5, h6, p) {
    color: @error;
  }
  .error-message {
    margin: 20px 0px;
    font-size: 20px;
  }
  .sub-title {
    color: #14181c;
    margin-top: 8px;
  }

  .contact-divider.ant-divider {
    border-bottom: 1px solid @border-color;
  }
  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: @text-detail;
      font-weight: 500;
    }
  }

  .change-quote-section {
    margin: 24px 0 22px 0;
    .back-link {
      .arrow {
        margin-right: 9px;
      }
      span {
        color: #14181c;
        font-weight: 450;
        font-size: 16px;
        word-break: break-all;
        text-decoration: underline;
      }
      :hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
    .amount-section {
      color: #14181c;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .form-section {
    text-align: center;
    .optional-comment {
      color: @black-color;
      font-weight: 500;
      font-size: 14px;
    }
    .mt-30 {
      margin-top: 30px;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-15 {
      margin-top: 15px;
    }
    .mt-3 {
      margin-top: 3px;
    }
    .common-checkbox .ant-checkbox-inner {
      height: 24px;
      width: 24px;

      &.ant-checkbox-inner {
        top: 3px;
      }
      &.ant-checkbox-inner::after {
        left: 27.5%;
      }
    }
    .common-checkbox .ant-checkbox-checked::after {
      top: 3px;
    }
  }

  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: @text-detail;
      font-weight: 500;
      display: flex;
    }
  }

  .contact-detail-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: unset;
  }
}
