@import '../globalComponents/styles/variables.less';

.common-config-component {
  font-family: 'Figtree', sans-serif;
  text-align: center;
  .general-section {
    .full-width-image {
      margin-left: -40px;
      margin-right: -40px;
      margin-top: 0px;
      width: auto;
      .header-image {
        height: 250px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .widget-section {
    margin: 20px 0;
    .my-project-component {
      .tile-grid {
        .tile-view {
          width: 50%;
        }
        .list-view {
          width: 100%;
        }
      }
    }

    // added !important over here as rc-virtual-list package uses inline css to hide scroll bar in select
    .pick-list-drop-down {
      .rc-virtual-list-holder {
        overflow-y: auto !important;
        transform: none;
      }
    }

    .dropdown-option {
      display: flex;
      flex-direction: column;
      .option-title {
        font-size: 16px;
        font-weight: 500;
        word-break: break-word;
      }
      .option-description {
        color: @field-placeholder;
        font-size: 14px;
        font-weight: 500;
        white-space: normal;
        padding: 0 20px;
        word-break: break-word;
      }
    }

    .ant-form-vertical .ant-form-item-label > label {
      font-size: 16px;
      color: @field-color;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      margin-bottom: 6px;
    }
  }
  .footer-section {
    .countdown-text {
      display: flex;
      margin-top: 15px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: @text-color-secondary;
      margin-bottom: 16px;
      word-break: break-word;
      .ant-statistic-content {
        font-size: 14px;
        color: @text-color-secondary;
        .ant-statistic-content-value {
          min-width: 64px;
        }
      }
    }
    .my-cart-btn {
      margin: auto;
      margin-top: 16px;
      padding: 8px 0;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      color: @text-primary;
      font-weight: 500;
      .count-badge {
        order: 1;
        margin-right: 8px;
      }
      .anticon {
        margin-left: 6px;
      }
      &:hover {
        background: transparent;
      }
      &:focus {
        background: transparent;
      }
    }
    .contact-me-btn {
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      text-decoration: underline;
      white-space: normal;
      word-break: break-word;
      height: unset;
      font-weight: 700;
      .anticon {
        margin-left: 8px;
      }
    }
    .back-btn {
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      :nth-child(2) {
        max-width: 700px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .anticon svg {
        vertical-align: middle;
      }
    }
    .footnote-text {
      margin-top: 16px;
      font-size: 12px;
      font-weight: 450;
    }
  }
  .title-section {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .thin-label {
    font-size: 16px;
    color: @text-color;
    font-weight: 400;
    margin-bottom: 4px;
    word-break: break-word;
  }
  .bold-label {
    font-size: 28px;
    color: @field-color;
    font-weight: 700;
    word-break: break-word;
  }
  .content-font-size {
    font-size: 16px;
    font-weight: 450;
  }
  .content-page {
    p,
    li {
      margin: 0;
      padding: 0;
    }
  }
}
