@import '../../styles/variables.less';

.document-component {
  .ant-upload.ant-upload-select-picture-card {
    background: @white-color;
    width: 138px;
    height: 137px;
    border: 1px dashed @upload-border;
  }
  .ant-upload-list-item-info > span {
    font-size: 8rem;
    color: @primary-color;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 52px;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-uploading.ant-upload-list-item {
    background: @white-color;
    border: 1px dashed @upload-border;
  }

  .ant-upload-list-picture-card-container {
    width: 138px;
    height: 180px;
    margin-right: 16px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    border-radius: 6px;
  }
  div.ant-upload-list-item-thumbnail {
    position: relative;
    top: 15px;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item-info
    .ant-upload-span.ant-upload-list-item-thumbnail
    .ant-upload-list-item-file
    .anticon-file {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 138px;
    height: 137px;
    background: @layout-background;
    border: none;
  }

  //below class is used for apply file type icon in attachment Note->currently not in used
  .csv-icon {
    .ant-upload-list-picture-card .ant-upload-list-item-list-type-picture-card {
      .ant-upload-list-item-info {
        background: url('../../assets/csv.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .ant-upload-list-picture .ant-upload-list-item:hover,
    .ant-upload-list-picture-card .ant-upload-list-item:hover {
      background: url('../../assets/csv.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    background: @white-color;
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    .upload-title {
      margin-top: 17px;
      font-size: 11px;
      color: @black-color;
    }
    .upload-description {
      margin-top: 3px;
      font-size: 12px;
      color: @upload-description;
    }
    .icon-header {
      font-size: 13px;
      color: @black-color;
    }
  }
  .document-label {
    width: 138px;
    text-align: center;
    margin-top: 10px;
    font-size: 11px;
    color: @black-color;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .file-type {
    width: 117px;
    height: 95px;
    margin: 22px 2px;
    background-color: @white-color;
  }
  .anticon.pdf {
    color: firebrick;
  }

  .anticon.csv {
    width: 117px;
    height: 114px;
    margin: 3px 2px;
    background-color: @white-color;
    color: #007c00;
    font-size: 11rem;
  }

  .anticon.ppt {
    width: 117px;
    height: 114px;
    margin: 3px 2px;
    background-color: @white-color;
    color: #cc4525;
    font-size: 11rem;
  }

  .anticon.doc {
    width: 117px;
    height: 114px;
    margin: 3px 2px;
    background-color: @white-color;
    color: #0f3d8c;
    font-size: 11rem;
  }
}
