@import '../../../styles/variables.less';

.left-content::-webkit-scrollbar {
  display: none;
}
.side-panel-container {
  .side-panel-wrapper {
    &.cpq-panel {
      background-color: @white-color;
      min-width: 235px;
      max-width: 235px;
    }
  }
}
.side-panel-open {
  .side-panel-container {
    .default-btn-position {
      &.questionnaire-btn {
        left: 214px;
      }
    }
  }
}

.left-content {
  height: 100vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  margin: 0;
  .body-scroll {
    height: calc(100vh - 118px);
    overflow-y: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  .body-scroll::-webkit-scrollbar {
    display: none;
  }

  .header-text {
    width: 100%;
    padding: 16px;
    color: @text-detail;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .template-no-data {
    height: calc(100vh - @layout-header-height);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-page-btn {
    display: flex;
    justify-content: center;
    width: 220px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 32px;
    box-shadow: 0px 6px 11px 0px;
  }
}

.row-dragging {
  z-index: 999;
}

#questionnaire-sortable-item {
  border: none;
  .template-card {
    padding: 2px 16px;
    cursor: pointer;
    padding-right: 0;
  }
  .draggable-card {
    padding: 2px 8px;
  }
  .selected-card {
    .ant-card-body {
      background-color: @white-color;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
    }
  }
  .card-title {
    color: @primary-color;
    font-weight: 600;
    font-size: 11px;
    word-break: break-word;
  }
  .active-template-card {
    background-color: @white-color;
    cursor: pointer;
  }
  .ant-card-body {
    background-color: @layout-background;
    width: 189px;
    border-radius: 6px;
    padding: 16px;
    .template-img {
      margin-right: 8px;
    }

    .click-rate {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      height: 18px;
      margin-top: 12px;
      color: @text-detail;
      .click-rate-text {
        margin-left: 4px;
        font-size: 10px;
        color: @text-color;
      }
    }
    .time-spend {
      margin-top: 6px;
      .time-row {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        font-weight: 500;
        color: @text-detail;
      }
      .time-details {
        color: #5e6168;
        font-size: 10px;
        font-weight: 500;
        margin-left: 6px;
      }
    }
    .delete-btn {
      height: 22px;
      width: 100%;
      margin-top: 16px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 10px;
    }
    .extra-space {
      margin-top: 13px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
