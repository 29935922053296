@import '../styles/variables.less';
@import './global/variables.less';
#root {
  height: 100vh;
  font-family: @font-family;
}

.ant-col.ant-form-item-label {
  margin-left: 9px;
  padding-bottom: 4px;
}
.ant-form-item-label > label {
  height: 14px;
  font-size: 10px;
  color: @text-color;
  font-weight: 400;
}
.ant-form-item-label > label {
  flex-direction: row-reverse;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: @text-color;
  margin-left: 3px;
}
.ant-form-item-has-error {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: @field-error;
  }
}
.ant-form-item-explain {
  font-size: 10px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  margin-top: 3px;
  color: @field-error;
  margin-left: 9px;
  font-size: 10px;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.ant-layout-sider-children {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    right: 21px;
  }
  .gx-sidebar-content {
    flex: 1.5;
    border-bottom: 0.5px solid @text-color;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
    & > .ant-menu {
      background: @nav-bar-bg;
      // .ant-menu-submenu-title {
      //   margin: 10px 0;
      // }

      & > .ant-menu-submenu-selected {
        & > .ant-menu-submenu-arrow {
          .ant-menu-submenu-arrow:after {
            background: @nav-bar-text-color;
          }
          .ant-menu-submenu-arrow:before {
            background: @nav-bar-text-color;
          }
        }
      }
      & > .ant-menu-item {
        margin: 10px 0;

        & > .menu-icons {
          font-size: 14px;
        }
        .anticon {
          font-size: 16px;
        }
        .anticon + span {
          margin-left: 0;
          font-size: 12px;
        }
      }
      .ant-menu-submenu-selected .ant-menu-submenu-title {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item-selected {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item:active,
      .ant-menu-submenu-title:active {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item:hover,
      .ant-menu-submenu-title:hover {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item::after {
        border-right: none;
      }
      & > .ant-menu-item:first-child {
        .ant-layout-sider-collapsed & {
          margin: 13px 0;
        }
      }
      & > .ant-menu-submenu:first-child {
        .ant-layout-sider-collapsed & {
          margin: 13px 0;
        }
      }
      & > .ant-menu-submenu {
        margin: 10px 0;
        .ant-layout-sider-collapsed & {
          margin: 33px 0;
        }
        padding: 0;
        & > .ant-menu {
          background: @nav-bar-bg;
        }
        .ant-menu-submenu-title {
          width: 100%;
          .anticon {
            font-size: 16px;
          }
          & > span {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
    .ant-menu-item:hover,
    .ant-menu-item:active,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: @white-color;
    }

    .ant-menu-inline-collapsed {
      width: 72px;
      & > .ant-menu-title-content {
        & > .anticon {
          font-size: 22px;
        }
      }
      & > .ant-menu-submenu {
        .ant-menu-submenu-title {
          & > .anticon {
            font-size: 22px;
          }
        }
      }
      .ant-menu-item {
        margin: 33px 0;
        & > .anticon {
          font-size: 22px;
        }
      }
    }
  }
  .gx-sidebar-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .gx-linebar {
    & > .ant-menu {
      background: @nav-bar-bg;
      & > .ant-menu-item {
        margin: 10px 0;

        & > .menu-icons {
          font-size: 14px;
        }

        & > span {
          font-size: 12px;
        }
      }
      .ant-menu-submenu-selected .ant-menu-submenu-title {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item-selected {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item:active,
      .ant-menu-submenu-title:active {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item:hover,
      .ant-menu-submenu-title:hover {
        color: @white-color;
        background: @selected-menu-bg-color;
        .ant-menu-submenu-arrow:after {
          background: @nav-bar-text-color;
        }
        .ant-menu-submenu-arrow:before {
          background: @nav-bar-text-color;
        }
      }
      & > .ant-menu-item::after {
        border-right: none;
      }
      & > .ant-menu-submenu {
        margin: 10px 0;
        padding: 0;
        & > .ant-menu {
          background: @nav-bar-bg;
        }
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          width: 100%;
          & > .anticon {
            font-size: 14px;
          }
          & > span {
            font-size: 12px;
          }
        }
      }
    }
    .ant-menu-inline-collapsed {
      & > .ant-menu-submenu {
        .ant-menu-submenu-title {
          & > .anticon {
            font-size: 22px;
          }
        }
      }
      & > .ant-menu-item {
        margin: 33px 0;
        & > .anticon {
          font-size: 22px;
        }
      }
    }
  }
  .gx-bottom-content {
    padding: 32px 25px;
    .ant-layout-sider-collapsed & {
      font-size: 22px;
      padding: 0px;
    }
    .user-name-section {
      display: flex;
      align-items: center;
      .anticon {
        font-size: 16px;
      }
      .ant-layout-sider-collapsed & {
        flex-direction: column;
      }
    }
    .icon-hover {
      cursor: pointer;
    }

    .icon-hover:first-child {
      .ant-layout-sider-collapsed & {
        margin-bottom: 32px;
      }
    }
    .user-name {
      font-size: 12px;
      margin-left: 15px;
      color: @menu-item-color;
    }
    .user-name:hover {
      text-decoration: underline;
    }
    .logout-btn {
      height: 9px;
      font-size: 12px;
      margin-top: 8px;
      margin-left: 32px;
      cursor: pointer;
      color: @menu-item-color;
    }
    .logout-btn:hover {
      text-decoration: underline;
    }
  }
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item
    > .ant-menu-title-content
    > .anticon {
    font-size: 22px;
  }
}

.ant-table-wrapper {
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  justify-content: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

.portal-header {
  text-transform: capitalize;
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  width: 700px;
  height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: 920px) {
    width: 200px;
  }
  @media screen and (min-width: 921px) and (max-width: 1024px) {
    width: 500px;
  }
}
//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.mx-16 {
  margin: 16px 0;
}

.mx-24 {
  margin: 24px 0;
}

.mt-0 {
  margin-top: 0px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-37 {
  margin-top: 37px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-0 {
  margin-right: 0;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-0 {
  margin-left: 0;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-24 {
  margin-left: 24px;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 2px;
}

.py-12 {
  padding: 12px 0px;
}

.px-12 {
  padding: 0 12px;
}

.px-16 {
  padding: 0 16px;
}

.pt-4 {
  padding-top: 4px;
}

.pl-0 {
  padding-left: 0;
}

.pl-24 {
  padding-left: 24px;
}

// flex-box related style
.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes

.width-percent-5 {
  width: 5%;
}

.width-percent-10 {
  width: 10%;
}

.width-percent-20 {
  width: 20%;
}

.width-percent-25 {
  width: 25%;
}

.width-percent-30 {
  width: 30%;
}

.width-percent-40 {
  width: 40%;
}
.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-70 {
  width: 70%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-90 {
  width: 90%;
}

.fill-width {
  width: 100%;
}

.width-22 {
  width: 22px;
}

.width-40 {
  width: 40px;
}

.width-32 {
  width: 32px;
}
//width classes

// image contain

.image-contain {
  object-fit: contain;
}

// image contain

// height
.full-height-card {
  min-height: calc(100vh - @layout-header-height);
  background-color: @layout-background;
  border: none;
  .import-button {
    width: 70px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .export-button {
    width: 67px;
  }

  // common class for table
  .common-table {
    .ant-table-placeholder {
      height: calc(100vh - 263px);
    }

    .ant-table-cell:last-child {
      .anticon-more {
        font-size: 16px;
        color: #96979b;
      }
    }

    .ant-table-tbody {
      color: @table-cell-color;
      font-weight: 400;
    }
    .ant-pagination {
      margin-top: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      .anticon {
        color: #898c94;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: @secondary-color-light;
        font-size: 12px;
        color: @text-color;
        border-radius: 5px;
        border: none;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item {
        background-color: @secondary-color-light;
        color: @text-color;
      }
      .ant-select-item-option-selected {
        background-color: @secondary-color-light;
      }
      .ant-pagination-item {
        border-radius: 5px;
        margin-left: 8px;
        background-color: @layout-background;
        border: 1px solid @secondary-color-light;
        a {
          color: #3f4750;
        }
      }
      .ant-pagination-item-active {
        background-color: @secondary-color-light;
        border: 1px solid @secondary-color-light;
        a {
          color: @text-color;
        }
      }
      .ant-select-item .ant-select-item-active {
        background-color: @secondary-color-light;
      }
      .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 500;
      }
      .anticon-left {
        font-size: 16px;
      }
      .anticon-right {
        margin-left: 8px;
        margin-right: 20px;
        color: @text-color;
        font-size: 16px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @secondary-color-light;
      }
    }
  }
  .first-column-table {
    td:first-child {
      font-weight: 500;
    }
    td {
      font-weight: 400;
    }
  }
  .search-checkbox {
    display: flex;
    height: 34px;
    align-items: center;
  }
  .search-checkbox-cpq {
    margin-bottom: 24px;
    display: flex;
    height: 44px;
    align-items: center;
  }
  .header-buttons {
    display: flex;
    min-width: 153px;
  }
  .card-container {
    .card-title {
      margin-left: 6px;
    }
    .ant-tabs-tab {
      color: @primary-color;
      height: @field-height;
      font-size: 11px;
      font-weight: 500;
    }
    .ant-tabs-card .ant-tabs-content {
      background-color: @white-color;
      font-size: 12px;
      height: calc(100vh - 144px);
      overflow-y: auto;
      margin-top: -16px;
      border-radius: 6px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.02);
    }
    .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
      padding: 16px;
      background: @white-color;
    }
    .ant-tabs-card > .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-card .ant-tabs-tab,
    [data-theme='compact'] .ant-tabs-card .ant-tabs-tab {
      background: transparent;
      border-color: transparent;
    }
    .ant-tabs-card .ant-tabs-tab-active,
    [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
      background: @white-color;
      border-color: @white-color;
    }
    [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
      height: 120px;
      margin-top: -8px;
    }
  }
  .required-row {
    margin-bottom: 32px;
  }
  .optional-divider {
    margin-left: 8px;
  }
  .form-divider {
    margin-top: 10px;
    margin-bottom: 16px;
    background-color: #f1f3f7;
  }
  .form-divider-text {
    font-size: 12px;
    color: @field-color;
    font-weight: 600;
    line-height: 16px;
  }

  // common class for view page detail
  .field-detail {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    min-height: 51px;
    margin-bottom: 16px;
    .field-label {
      margin-bottom: 8px;
      color: @text-color;
      font-size: 10px;
    }
    .field-value {
      height: @field-height;
      color: @text-detail;
      font-size: 12px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .pick-list-field-value {
      color: @text-detail;
      font-size: 12px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .field-description {
      height: unset;
    }
    .field-logo {
      object-fit: contain;
      height: 61px;
      width: 64px;
    }
  }
  .content-section {
    padding: 16px;
    background-color: @white-color;
    height: calc(100vh - 86px);
    overflow-y: auto;
  }
}

.list-search-box {
  font-weight: 500;
  border: none;
  border-radius: 6px;
  height: 34px;
  width: 100%;
  background-color: @secondary-color-light;
  color: @field-color;
  .ant-input {
    font-size: @field-font-size;
    color: @field-color;
    background-color: @secondary-color-light;
  }
  .ant-input::placeholder {
    color: @placeholder-color;
    font-weight: 400;
  }
  .anticon-search {
    color: @text-color;
    font-size: 18px;
    margin-right: 5px;
  }
}

.list-search-box-cpq {
  font-weight: 400;
  border: none;
  border-radius: 10px;
  height: 44px;
  width: 100%;
  background-color: @field-background-color;
  color: @field-color;
  .ant-input {
    text-align: center;
    font-size: 14px;
    color: @field-color;
    background-color: @field-background-color;
  }
  .ant-input::placeholder {
    color: #808080;
  }
  .anticon-search {
    margin-left: 11px;
    color: #dadada;
  }
  &.ant-input-affix-wrapper-sm {
    padding: 2px 11px;
  }
}

.export-btn-dropdown {
  .ant-dropdown-menu-item-active {
    background-color: @secondary-color-light;
  }
}

.ant-table-filter-dropdown .amount-filter-dropdown {
  width: 270px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    margin-left: 9px;
    font-size: 10px;
  }
}

.ant-table-filter-dropdown .range-filter-dropdown {
  width: 300px;
  .ant-picker {
    border: none;
    background-color: @field-background-color;
    color: @field-color;
    font-weight: 500;
    border-radius: 6px;
    height: @field-height;
    padding: 8px;
    transition: none;
  }
  .ant-picker-input > input::placeholder {
    color: @placeholder-color;
    font-weight: 400;
  }

  .ant-picker-input > input:placeholder-shown {
    background-color: @field-background-color;
  }
  .ant-picker-range .ant-picker-clear {
    right: 25px;
  }
  .ant-picker-input > input {
    color: @field-color;
  }
  .ant-picker-suffix {
    color: @primary-color;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    margin-left: 9px;
    font-size: 10px;
  }
}

.custom-filter-dropdown {
  font-family: @font-family;
  padding: 12px;
  width: 254px;
  word-wrap: break-word;

  .range-input {
    margin-top: 10px;
  }
  .filter-search {
    width: 100%;
    margin-bottom: 12px;
  }
  .divider-filter {
    margin: 10px 0px;
  }

  .filter-button {
    width: 69px;
    display: flex;
    justify-content: center;
  }
  .filter-checkboxes {
    margin-top: 10px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    .filter-checkbox-section {
      margin-bottom: 9px;
    }
  }
  .selected-divider {
    font-size: 13px;
    margin: 0px;
  }
  .filter-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-tag {
      margin: 4px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      span:first-child {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    margin-left: 9px;
    font-size: 10px;
  }
}

// common class for header button
.common-button {
  display: flex;
  align-items: center;
  height: 22px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  box-shadow: 0px 6px 11px rgba(29, 34, 40, 0.16);
  img {
    margin-right: 5px;
  }
  .anticon {
    font-size: 14px;
  }
  .anticon + span {
    margin-left: 5px;
  }
}
.ant-btn-primary[disabled].common-button {
  color: @white-color;
  background: #a3a3ad;
}

// common class for textarea
.common-textarea {
  background-color: @field-background-color;
  border: none;
  font-weight: 500;
  font-size: @field-font-size;
  color: @field-color;
  border-radius: @field-border-radius;
  .ant-input {
    background-color: @field-background-color;
    border: none;
    border-radius: 10px;
    font-size: @field-font-size;
    font-weight: 500;
    color: @field-color;
    &::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
  }
  .ant-input:hover {
    box-shadow: none;
    border: none;
  }
  &.ant-input:focus,
  &.ant-input-focused {
    box-shadow: none;
    border: none;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-radius: 10px;
    background-color: @field-background-color;
    border: 1px solid @field-error;
  }
}

.ant-form-item-has-error {
  &.common-upload {
    .ant-upload-drag {
      border: 1px dashed @field-error;
    }
  }
}
.discard-button {
  border: 1px solid @primary-color;
  font-weight: 600;
  color: @primary-color;
  margin-right: 8px;
  box-shadow: none;
  background-color: @white-color;
  span:last-child {
    line-height: 12px;
  }
  span.anticon {
    height: 14px;
    font-size: 14px;
  }
}
.action-button {
  .ant-popover-inner-content {
    padding: 0;
    min-width: 103px;
  }
  .ant-btn {
    color: #15161b;
    text-align: left;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background-color: @secondary-color-light;
  }
}

.import-common-modal {
  font-family: @font-family;
  .module-text {
    color: @text-color;
    font-size: 10px;
  }
  .module-select {
    margin-top: 3px;
    margin-bottom: 16px;
  }
  .link-btn {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 8px;
    color: @primary-color;
    cursor: pointer;
    text-decoration: underline;
  }
  .info-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-modal-close-x {
    width: 30px;
    margin-right: 15px;
  }
  .import-title {
    margin-right: 35px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    color: #f5222d;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-actions
    .anticon-delete:hover {
    color: #f5222d;
  }
}

.info-tooltip {
  font-family: @font-family;
  color: @field-color;
  width: 337px;
  .ant-popover-title {
    font-weight: 600;
  }
  .ant-popover-inner-content {
    padding: 12px 12px 12px 0;
  }
}
// height

.card-body-p-0 {
  .ant-card-body:first-child {
    padding: 0;
  }
}

.card-body-p-20 {
  .ant-card-body:first-child {
    padding: 20px;
  }
}

.card-body-padding {
  .ant-card-body:first-child {
    padding: 16px 24px;
    min-height: inherit;
  }
}

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//box shadow
.bs-none {
  box-shadow: none;
}

//border style
.b-0 {
  border: none;
}
//border style

//display style
.display-none {
  display: none;
}

.display-block {
  display: block;
}
//display style

// line clamp
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 5px;
}
//line clamp

// date
.date-time {
  color: #999999;
  font-size: 9px;
}

//radio button
.common-radio {
  .ant-radio-wrapper {
    height: 24px;
  }
  .ant-radio-inner:after {
    width: 0;
    height: 0;
  }

  .ant-radio-checked .ant-radio-inner {
    border: @radio-border-width solid @primary-color;
    height: @radio-size;
    width: @radio-size;
  }

  .ant-radio-inner {
    height: @radio-size;
    width: @radio-size;
    border: 1.5px solid @primary-color;
  }

  span.ant-radio + * {
    padding: 0 10px;
    font-weight: 500;
    font-size: @field-font-size;
    color: @field-color;
    line-height: 18px;
    margin: auto;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
}

// common map class
.common-map {
  z-index: 1000;
  height: 340px;
  width: 99vw;
  position: relative;
  left: 54%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    left: 51%;
  }
}

//common description ellipses class
.html-description :first-child {
  text-overflow: ellipsis;
  overflow: hidden;
}

// common time picker
.common-time-picker {
  &.ant-picker {
    background: @field-background-color;
    height: @field-height;
    width: 151px;
    border: none;
  }
  .ant-picker-input > input {
    color: @field-color;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: @font-family;
  }
  .ant-picker-suffix {
    color: @primary-color;
  }
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: @white-color;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background: @field-background-color;
}

.common-time-picker.ant-form-item-has-error
  .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background: @field-background-color;
}

// common time range picker

.common-range-picker {
  &.ant-picker {
    background-color: @field-background-color;
    border: none;
    height: @field-height;
    .ant-picker-input > input {
      font-weight: 500;
      font-size: 12px;
      color: @text-detail-secondary;
    }
  }
  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
    color: @white-color;
  }
}
.common-range-picker {
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    font-weight: 500;
    font-size: 12px;
    color: @text-detail-secondary;
  }
}
.common-range-picker-dropdown {
  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-form-item-has-error .common-range-picker.ant-picker:not([disabled]):hover {
  background-color: @field-background-color;
}

.date-column-min-width {
  min-width: 150px;
}

.max-width-column {
  max-width: 400px;
}

// font size

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-32 {
  font-size: 32px;
}

// font weight

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

// Cpq-retail common css

// logo
.logo-section {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  img {
    width: 300px;
    max-height: 150px;
    object-fit: contain;
  }
  .second-logo {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .logo-text {
    color: @text-color;
    font-weight: 450;
    display: flex;
    align-items: center;
  }
}

// common map class
.common-map-cpq {
  border-radius: 50%;
  width: 100%;
  max-width: 375px;
  aspect-ratio: 1 / 1;
  margin: auto;
}

// button
.common-button-cpq {
  width: 100%;
  height: unset;
  min-height: 44px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 6px 15px fade(@primary-color, 25%);
  white-space: normal;
  word-break: break-word;
}

// footer
.location-footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .location-info {
    color: @primary-color;
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
  }
}

// contact
.contact-section {
  margin-top: 16px;
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .details-text {
      cursor: pointer;
      font-size: 12px;
      color: #797c86;
      line-height: 18px;
      a {
        color: unset;
      }
    }
    .underline-text {
      line-height: 16px;
      margin-top: 20px;
      text-decoration: underline;
      .anticon {
        margin-right: 8px;
      }
      a {
        color: unset;
      }
    }
  }
  .email-me-section {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .email-me-btn {
      font-weight: 600;
      height: 24px;
      padding: 0;
      text-decoration: underline;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .anticon {
        margin-left: 10px;
        align-self: end;
      }
    }
    .email-me-btn:hover {
      text-decoration: underline;
    }
  }
}

fieldset {
  position: relative;
  border: none;
}

fieldset[disabled]::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.01);
}

fieldset[disabled] {
  [disabled] {
    pointer-events: none;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    pointer-events: none;
  }
  .ant-select.select-width {
    pointer-events: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    pointer-events: none;
  }
}

// common class for checkbox
.common-checkbox {
  width: 100%;
  margin-left: 0px;
  min-height: 24px;
  font-size: @field-font-size;
  color: @field-color;
  font-weight: 400;
  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .ant-checkbox + span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: @field-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    min-width: 90px;
  }
  .ant-checkbox-inner {
    height: @checkbox-size;
    width: @checkbox-size;
    border-radius: @checkbox-border-radius;
    border: 1.5px solid @field-color;
  }
  .ant-checkbox-group-item {
    margin-bottom: 8px;
  }
}

.common-checkbox-cpq {
  font-size: 14px;
  font-weight: 500;
  .ant-checkbox + span {
    color: @field-color;
    padding: 0px 12px;
    align-self: center;
    word-break: break-word;
    font-weight: 500;
  }
  .ant-checkbox-inner::after {
    left: 35%;
  }
  .ant-checkbox-inner {
    background-color: @field-background-color;
    border: none;
    border-radius: 4px;
    height: 24px;
    width: 24px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
  }
}

// common collapse
.common-collapse {
  &.ant-collapse {
    &.sub-collapse {
      padding: 0 16px;
    }
    & .ant-collapse-item > .ant-collapse-header {
      font-size: 12px;
      padding: 16px;
      padding-left: 0;
      color: @text-detail;
      font-weight: 600;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;
      background-color: @white-color;
      border-bottom: 1px solid @field-background-color;
    }
    &.ant-collapse-ghost {
      .ant-collapse-item > .ant-collapse-header {
        background-color: transparent;
      }
    }
    & .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
      float: right;
      margin-right: 30px;
    }

    .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
      color: @text-detail;
      cursor: pointer;
    }
    &.ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      background-color: @white-color;
      padding: 8px 0;
    }
    &.ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 0;
    }
  }
  &.ant-collapse-ghost {
    & .ant-collapse-item > .ant-collapse-header {
      border: none;
    }
  }
}

// common class for switch
.common-switch {
  background-color: @field-background-color;
  width: 28px;
  min-width: 28px;
  height: 14px;
  .ant-switch-handle {
    height: 10px;
    width: 10px;
    border: 1.5px solid @primary-color;
    background-color: @field-background-color;
    border-radius: 10px;
    .ant-switch-checked & {
      right: 4px;
    }
  }
  .ant-switch-handle::before {
    background-color: transparent;
  }
  .ant-switch-inner {
    margin: 0;
    width: 28px;
    height: 14px;
    position: relative;
    .anticon-close {
      color: @switch-color;
      font-size: 8px;
      padding: 0;
      position: absolute;
      top: 3px;
      right: 4px;
    }
  }
  .ant-switch-loading-icon.anticon {
    position: relative;
    padding: 0;
    top: -1px;
    left: -1px;
    color: @white-color;
    font-size: 9px;
    height: 9px;
  }
  &.ant-switch-checked {
    background-color: @primary-color;
    .ant-switch-handle {
      border: 1.5px solid @white-color;
      background-color: @white-color;
      position: absolute;
      right: 2px;
      left: auto;
    }
    .anticon-check {
      color: @switch-color;
      font-size: 8px;
      padding: 0;
      position: absolute;
      top: 3px;
      left: 4px;
    }
    .ant-switch-loading-icon.anticon {
      z-index: 999;
      color: @primary-color;
    }
  }
  &.ant-switch:focus {
    box-shadow: none;
  }
  &.ant-switch-checked:focus {
    box-shadow: none;
  }
}

// common for tabs icon

.tab-icons.anticon {
  margin-right: 0px;
  vertical-align: bottom;
}

// class for primary color

.primary-color {
  color: @primary-color;
}

// no access page

.no-access-page {
  .ant-card-body {
    height: calc((100vh - 58px));
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// common class for list image
.list-img {
  margin-right: 8px;
  margin-bottom: 3px;
}

// notification
.top-notification {
  width: 540px;
  font-weight: bold;
}

.email-verify-alert {
  line-height: 24px;
  padding: 8px 24px;
  // background-color: #e05753; old color might need for future use
  background-color: #da3a34;
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 450px;
  ::before {
    background: unset;
  }
  .ant-alert-icon {
    color: @white-color;
  }
  .ant-alert-message {
    font-size: 12px;
    font-weight: 500;
    color: @white-color;
  }
  .ant-alert-close-icon .anticon-close {
    color: @white-color;
  }
  .resend-verification-btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: @white-color;
    text-decoration: underline;
    font-weight: 500;
    :not([disabled]):hover {
      text-decoration: underline;
    }
    span {
      text-decoration: underline;
    }
  }
}
.login-button {
  height: 36px;
  display: block;
}

// portal class
#header-right-content {
  display: flex;
}

// router-prompt
.router-prompt {
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    .common-button {
      margin-right: 0px;
    }
  }
}

.checked-success-color {
  color: @success-color;
}

.check-info {
  cursor: pointer;
  margin-left: 6px;
  vertical-align: bottom;
}

.label-with-tooltip {
  .ant-form-item-label {
    label {
      flex-direction: row;
      align-items: flex-end;
      .ant-form-item-tooltip {
        display: flex;
        order: 1;
        margin-inline-start: 4px;
      }
    }
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: flex;
      order: 1;
    }
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      .ant-form-item-tooltip {
        margin-inline-start: 0px;
        margin-bottom: 4px;
      }
    }
  }
}

// panel empty space
.panel-empty {
  margin: 0;
}
.text-form-item {
  .ant-col.ant-form-item-label {
    margin-left: 0;
  }
}

// font weight

.font-500 {
  font-weight: 500;
}

// danger
.danger {
  color: @error;
}

.hide-required-mark {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

.preview-tag {
  min-height: 32px;
  margin: auto;
  font-size: 10px;
  background-color: #e7e9f1;
  width: 320px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
}

.break-word {
  word-break: break-word;
}

.h-34 {
  height: 34px;
}

// editor component div class
.editor-render {
  :is(body, div, h1, h2, h3, h4, h5, h6, p) {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  :is(ul, ol, li) {
    margin: 0;
    padding: 0;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 1.5em;
    padding-inline-end: 0;
    list-style-position: inside;
  }

  :is(ul, li) {
    list-style-position: outside;
  }
  a {
    text-decoration: underline;
  }
}

.common-tooltip {
  .ant-popover-inner-content {
    width: 340px;
    max-width: max-content;
    word-break: break-word;

    .line-height {
      line-height: 1.5;
    }
  }
}

.link-btn-campaign-code:hover {
  cursor: pointer;
  text-decoration: underline;
}
