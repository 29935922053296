@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.add-on-products {
  .common-table {
    .ant-table-placeholder {
      height: calc(100vh - 263px);
    }

    .ant-table-cell:last-child {
      .anticon-more {
        font-size: 16px;
        color: #96979b;
      }
    }

    .ant-table-tbody {
      color: @table-cell-color;
      font-weight: 400;
    }
    .ant-pagination {
      margin-top: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      .anticon {
        color: #898c94;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: @secondary-color-light;
        font-size: 12px;
        color: @text-color;
        border-radius: 5px;
        border: none;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item {
        background-color: @secondary-color-light;
        color: @text-color;
      }
      .ant-select-item-option-selected {
        background-color: @secondary-color-light;
      }
      .ant-pagination-item {
        border-radius: 5px;
        margin-left: 8px;
        background-color: @layout-background;
        border: 1px solid @secondary-color-light;
        a {
          color: #3f4750;
        }
      }
      .ant-pagination-item-active {
        background-color: @secondary-color-light;
        border: 1px solid @secondary-color-light;
        a {
          color: @text-color;
        }
      }
      .ant-select-item .ant-select-item-active {
        background-color: @secondary-color-light;
      }
      .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 500;
      }
      .anticon-left {
        font-size: 16px;
      }
      .anticon-right {
        margin-left: 8px;
        margin-right: 20px;
        color: @text-color;
        font-size: 16px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @secondary-color-light;
      }
    }
  }
}
