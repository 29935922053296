@import '../../styles/variables.less';

.dynamic-fields-module {
  .select-all-text {
    font-size: 10px;
    margin-bottom: 16px;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: @primary-color;
  }
  .warning-text {
    font-size: 12px;
    color: #b86f02;
  }
  .upload-error {
    font-size: 10px;
    color: @field-error;
  }
  .content-section {
    .common-table {
      &.pick-list-table {
        .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
          height: 100px;
        }
      }
    }
  }
}

.pick-list-modal {
  .ant-modal.modal .ant-modal-body {
    overflow-x: hidden;
  }
}

.confirm-modal {
  .ant-modal-confirm-btns {
    display: flex;
  }
  .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-left: 0;
  }
}

.save-draft-btn {
  margin-right: 8px;
}

.publish-modal {
  .ant-modal-header {
    .ant-modal-title {
      font-size: 12px;
    }
  }
  .discard-margin {
    margin-right: 0;
  }
  .footer-btn {
    display: flex;
    justify-content: center;
    min-width: 85px;
  }
}

.confirm-modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
  }

  .custom-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .content-icon.anticon.anticon-exclamation-circle {
      font-size: 20px;
    }
  }
  .ant-modal-confirm-content {
    display: flex;
  }
}
