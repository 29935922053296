@import '../../styles/variables.less';

.ant-modal {
  &.modal {
    .ant-modal-title {
      color: @field-color;
      font-weight: 600;
    }
    .ant-modal-close-x {
      .anticon-close {
        color: @text-color;
      }
    }
    .ant-modal-header {
      padding: 16px;
    }
    .ant-modal-body {
      max-height: 500px;
      overflow-y: auto;
      padding: 16px;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
