@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.editor-component {
  font-family: @font-family;
  border-radius: 6px;
  background: @field-background-color;
  color: @field-color;
  font-weight: 500;
  .ql-formats {
    margin: 4px;
  }
  .ql-editor {
    font-family: @font-family;
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    background: @white-color;
    margin-right: 8px;
    border-radius: 5px;
  }
  .ql-snow .ql-tooltip {
    transform: translate(50px, 10px);
  }
  .ql-picker-label {
    background: @white-color;
    border-radius: 5px;
  }
  .ql-container.ql-snow {
    border: none;
    min-height: 76px;
  }
  .ql-toolbar.ql-snow {
    border: none;
  }
}

.ant-form-item-has-error {
  .editor-component {
    background: @field-background-color;
    border: 1px solid @field-error;
  }
}
