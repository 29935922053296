@import '../../styles/variables.less';

.common-loader {
  &.ant-spin {
    &.ant-spin-spinning {
      .ant-spin-nested-loading & {
        max-height: unset;
      }
    }
  }
}
