@import '../../styles/variables.less';

.ant-table {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 10px 16px;
  }
  .ant-table-column-sorter {
    margin-left: 4px;
  }
  td:nth-child(2) {
    font-weight: 500;
  }
  .ant-table-row-expand-icon {
    border: 1px solid @primary-color;
    border-radius: 3px;
  }
  td.ant-table-column-sort {
    background-color: @white-color;
  }
  .ant-table-placeholder {
    .ant-table-cell {
      border: none;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: @layout-background;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: @field-background-color;
  }
  .ant-table-placeholder {
    height: calc(100vh - 241px);
  }
  .ant-table-cell {
    .ant-checkbox-inner {
      height: @checkbox-size;
      width: @checkbox-size;
      border-radius: @checkbox-border-radius;
      border: 1.5px solid @field-color;
    }
    .ant-checkbox {
      margin-bottom: 6px;
    }
    .ant-checkbox-disabled + span {
      color: @field-color;
      cursor: not-allowed;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @primary-color;
    }
    .ant-radio-wrapper {
      height: 24px;
    }
    .ant-radio-inner:after {
      width: 0;
      height: 0;
    }

    .ant-radio-checked .ant-radio-inner {
      border: @radio-border-width solid @primary-color;
      height: @radio-size;
      width: @radio-size;
    }

    .ant-radio-inner {
      height: @radio-size;
      width: @radio-size;
      border: 1.5px solid @primary-color;
    }

    span.ant-radio + * {
      padding: 0 10px;
      font-weight: 500;
      font-size: @field-font-size;
      color: @field-color;
      line-height: 18px;
      margin: auto;
    }
  }
  .ant-table-thead > tr > th {
    font-size: 12px;
    color: @table-header-color;
    background: @table-header-background;
    font-weight: 500;
    border-bottom: none;
  }
  .ant-table-thead .ant-table-cell.ant-table-column-has-sorters:hover {
    background-color: @white-color;
  }
  .ant-table-thead .ant-table-cell:hover {
    background-color: @white-color;
  }
  .ant-table-thead .ant-table-cell.ant-table-column-has-sorters {
    height: 51px;
    padding: 0px 16px;
  }
  .ant-table-tbody > tr > td {
    color: @table-cell-color;
    font-size: 13px;
    height: 51px;
    padding: 0px 16px;
    border-bottom: 0.75px solid #afb4c5;
  }
  & .ant-table-tbody {
    & .anticon {
      font-size: 12px;
      padding: 0 4px;
    }
    & .ant-btn {
      margin: 0;
      padding: 1px 8px;
      height: auto;
    }
  }
  .ant-table-filter-column {
    justify-content: unset;
  }
  .ant-table-column-title {
    flex: unset;
  }
  .ant-table-column-sorters {
    justify-content: unset;
  }

  .ant-table-summary {
    .ant-table-cell {
      color: @black-color;
      font-weight: 500;
    }
  }
}

.has-scroll-data {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-with-data);
    }
  }
}

.has-scroll-data-search {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-with-data-search);
    }
  }
}

.no-scroll-data {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-without-data);
    }
  }
}

.no-scroll-no-data {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-with-data);
      .ant-table-placeholder {
        height: calc(100vh - 263px);
      }
    }
  }
}
