@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.plan-module {
  .ant-card-body {
    padding: 24px;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab {
    font-size: 12px;
    color: @text-detail;
  }
  .ant-tabs-tab:hover {
    color: @primary-color;
  }

  .plan-card-wrapper {
    margin-top: 70px;
    .plan-card {
      width: 264px;
      height: 406px;
      border-radius: 6px;
      text-align: center;
      background-color: @primary-color;
      display: flex;
      flex-direction: column;
      .plan-title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 30px;
        color: @white-color;
      }
      .plan-description {
        margin-top: 27px;
        font-size: 12px;
        padding: 0 27px;
        color: #ededed;
      }

      .bullet-points {
        margin-top: 31px;
        padding: 0 40px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: @white-color;
        font-weight: 500;
        font-size: 13px;
        img {
          margin-right: 12px;
        }
        :not(:first-child) {
          margin-top: 23px;
        }
      }
      .footnote {
        margin-top: 80px;
        font-weight: 600;
        font-size: 14px;
        color: @white-color;
      }
    }
  }
}
