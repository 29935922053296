@import '../../../../styles/variables.less';

.dynamic-render-select {
  .select-width {
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background: @field-background-color;
      color: @primary-color;
    }
  }
}
