@import '../../styles/variables.less';
@import '../../styles/global/variables.less';
.api-setting-wrapper {
  .copy-icon {
    cursor: pointer;
  }
  .api-token-label {
    font-size: 12px;
    color: @text-detail;
    font-weight: 600;
  }
  .content-section {
    .switch-margin {
      margin-bottom: 0px;
    }
  }
  .api-setting-switch {
    background-color: @field-background-color;
    .ant-switch-inner {
      color: @text-color;
    }
    &.ant-switch-checked {
      background-color: @primary-color;
      .ant-switch-inner {
        color: @white-color;
      }
    }
  }
}
