@import '../../styles/variables.less';

.product-item-module {
  .create-product {
    margin-top: 25px;
  }

  .product-item-nodata {
    min-height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .optional-fields {
    margin-bottom: 32px;
  }
  .optional-fields-popover {
    height: 16px;
    margin-top: 10px;
  }
}

.create-product-modal {
  .btn-bottom-margin {
    margin-bottom: 0px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    font-size: 10px;
  }

  .ant-modal.modal .ant-modal-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: @text-detail;
  }

  .ant-modal.modal .ant-modal-body {
    padding: 24px;
    .create-product-required-row {
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: @text-color;
        margin-left: 3px;
      }
      .ant-form-item-has-error {
        .ant-form-item-label
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          color: @field-error;
        }
      }
    }
  }

  .create-product-modal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 35px;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: @white-color;
    color: @text-color;
  }
}

.unit-of-measure-tooltip {
  .single-unit {
    margin-top: 8px;
  }
}

.unit-of-measure-padding {
  padding: 0px 8px 12px 8px;
}

.quote-description-popover {
  .ant-tooltip-inner {
    width: 280px;
  }
}
