@import '../../styles/variables.less';

// common class for upload
.common-upload {
  .ant-upload.ant-upload-drag {
    height: 135px;
    background: @white-color;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    height: 135px;
  }
  .ant-upload.ant-upload-drag.ant-upload-drag-hover {
    background: @primary-color;
    .icon-header {
      color: @white-color;
    }
    .upload-title {
      color: @white-color;
    }
    .upload-description {
      color: @white-color;
    }
  }
  .hide-border {
    display: none;
  }
  .icon-header {
    color: @primary-color;
    font-size: 20px;
  }
  .upload-title {
    margin-top: 18px;
    font-size: 16px;
    color: @header-text-color;
    margin-bottom: 4px;
  }
  .upload-description {
    color: #8c8c8c;
    font-size: 14px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    background: @layout-background;
    border: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    background: @white-color;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
  }
  .ant-upload-list-picture-card {
    .ant-upload-list-item-actions {
      .ant-btn-icon-only.ant-btn-sm {
        padding: 0;
      }
    }
  }
}

.ant-form-item {
  .common-upload {
    .ant-upload.ant-upload-drag {
      background: @white-color;
    }
  }
}
