@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.position-relative {
  position: relative;
}
.float-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  .ant-alert-with-description .ant-alert-icon {
    font-size: 20px;
    margin-right: 5px;
  }
  .ant-alert-with-description {
    padding: 3px 3px;
    color: @black-color;
  }
}

.pricing-modifier-module {
  .pricing-modifier-table {
    .no-scroll-data .ant-table .ant-table-body {
      height: calc(100vh - 221px);
      min-height: 0;
    }
  }
  .pricing-condition {
    display: inline-flex;
    align-items: center;

    .condition-max-width {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .condition-region {
      height: 24px;
      margin-left: 3px;
      font-size: 12px;
      background: @white-color;
      border-radius: 5px;
      max-width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      background-color: @show-more-bg;
      color: @white-color;
    }
    .condition-region.ant-tag {
      padding-top: 2px;
    }
    .condition-title {
      font-weight: 700;
      font-family: @font-family;
      color: @text-detail-secondary;
      font-size: 12px;
      line-height: 16px;
    }
    .condition-data {
      margin-left: 3px;
      font-weight: 400;
      font-family: @font-family;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
    }
    .show-more-img {
      vertical-align: baseline;
      margin-left: 2px;
    }
    .more-shadow {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
      background-color: @show-more-bg;
      color: @white-color;
    }
    .show-more-icon {
      font-size: 10px;
    }
    .ml-5 {
      margin-left: 5px;
    }
  }

  .radio-description {
    font-size: 12px;
    color: @text-color;
    line-height: 16px;
    margin-bottom: 17px;
  }
  .top-margin {
    margin-top: 36px;
  }
  .input-margin {
    margin-bottom: 8px;
  }
  .pricing-list {
    border-bottom: none;
    .ant-list-items {
      .single-row {
        display: flex;
        padding: 0 10px;
        border-bottom: 1px solid @tag-border;
      }
      .single-row {
        border-bottom: none;
      }
      .pricing-head-section {
        border-right: 1px solid @tag-border;
        width: 15%;
        display: flex;
        align-items: center;
        min-height: 48px;
        .pricing-title {
          font-size: 13px;
          font-weight: 500;
          color: #15161b;
          line-height: 20px;
        }
      }
      .pricing-middle-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
        .single-item {
          background-color: @show-more-bg;
          color: @white-color;
          height: 24px;
          padding: 4px 8px;
          margin: 4px;
          border-radius: 4px;
          .content-text {
            display: flex;
            line-height: 16px;
          }
          .close-img {
            display: flex;
            align-items: center;
            font-size: 10px;
            cursor: pointer;
            vertical-align: baseline;
            margin-left: 2px;
          }
        }
        .tag-plus {
          cursor: pointer;
          padding: 3px 8px;
          margin: 4px;
          height: 24px;
          border: 1px dashed @secondary-color;
          background: #f8f9fb;
          color: @primary-color;
          .content-text {
            font-weight: 500;
            display: flex;
            align-items: center;
            line-height: 18px;
            .anticon-plus {
              margin-right: 3px;
            }
          }
        }
      }
      .pricing-end-section {
        display: flex;
        align-items: center;
        .pricing-delete {
          cursor: pointer;
        }
      }
    }
  }

  .pricing-list:last-child {
    border-bottom: 1px solid @tag-border;
  }
  .error-text {
    font-size: 10px;
    color: @field-error;
    margin-left: 15px;
  }
  .block-parent {
    .lob-block {
      margin-top: 7px;
      margin-right: 10px;
      background-color: @field-background-color;
      border-radius: 10px;
      .ant-divider-horizontal {
        margin: 0;
      }
      .bold-label {
        text-transform: uppercase;
        color: @text-detail;
        font-weight: 600;
      }
      .lob-section {
        margin: 16px;
        .ant-checkbox + span {
          white-space: pre-wrap;
        }
      }
      .subarea-section {
        width: 100%;
        margin: 16px;
      }
      .inline {
        display: inline;
      }
    }
  }
  .full-height-card .field-detail {
    .field-label {
      margin-bottom: 0px;
    }
  }
  .full-height-card .field-detail {
    margin-bottom: 10px;
  }
  .custom-error-message {
    font-size: 10px;
    color: @error;
  }
}

.pricing-modifier-modal {
  .ant-modal-content .ant-modal-body {
    max-height: unset;
    height: calc(100vh - 150px);
  }
  .ant-modal-footer {
    border-top: unset;
  }
  .center-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .check-all {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
  }
  .block-parent {
    .lob-block {
      margin-top: 24px;
      margin-right: 10px;
      background-color: @field-background-color;
      border-radius: 10px;
      .ant-divider-horizontal {
        margin: 0;
      }
      .bold-label {
        text-transform: uppercase;
        color: @text-detail;
        font-weight: 600;
      }
      .lob-section {
        margin: 16px;
        .ant-checkbox + span {
          white-space: pre-wrap;
        }
      }
      .subarea-section {
        width: 100%;
        margin: 16px;
      }
      .inline {
        display: inline;
      }
    }
  }
  .no-data-image {
    height: calc(100vh - 315px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .collapse-text {
    &.common-collapse {
      &.ant-collapse {
        & .ant-collapse-item > .ant-collapse-header {
          text-transform: unset;
        }
      }
    }
  }
  .panel-height {
    &.ant-collapse-item {
      .ant-collapse-header {
        padding: 12px 0;
      }
    }
  }
  .panel-title {
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
    .collapse-checkbox {
      max-width: 150px;
      .ant-checkbox + span {
        white-space: pre-wrap;
      }
    }
    .left-space {
      margin-left: 24px;
    }
  }
  .region-margin {
    margin-top: 4px;
  }
  .single-item {
    cursor: pointer;
    background-color: @postal-code-bg;
    color: @white-color;
    font-weight: 400;
    height: 24px;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    .content-text {
      display: flex;
      line-height: 16px;
    }
    .close-img {
      display: flex;
      align-items: center;
      font-size: 10px;
      cursor: pointer;
      margin-left: 2px;
    }
  }
  .zip-highlight {
    border: 1px solid @primary-color;
  }
  .tag-plus {
    width: 100px;
    cursor: pointer;
    padding: 3px 8px;
    margin: 4px;
    height: 24px;
    border: 1px dashed @secondary-color;
    background: #f8f9fb;
    color: @primary-color;
    .content-text {
      font-weight: 500;
      display: flex;
      align-items: center;
      line-height: 18px;
      .anticon-plus {
        margin-right: 3px;
      }
    }
  }
  .no-results {
    display: flex;
    margin-left: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 10px;
    color: @text-color;
  }
  .region-tags {
    max-width: max-content;
  }
  .region-only {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .add-condition-button {
    height: 24px;
  }
  .parent-region.panel-height.ant-collapse-item {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-left: 50px;
      }
    }
  }
  .sub-region.panel-height.ant-collapse-item {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-left: 66px;
      }
    }
  }

  .custom-number.price-component.input-component.ant-input-affix-wrapper {
    border: 1px solid @border-color;

    &.number-error {
      border: 1px solid @error-color;
    }
  }
  .custom-error-message {
    font-size: 10px;
    color: @error;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 10px 30px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.drag-row {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.pricing-popover {
  &.ant-popover {
    max-width: 50%;
  }
  .pricing-condition {
    align-items: baseline;
    .condition {
      margin: 8px;
      display: flex;
      align-items: center;
      max-width: 400px;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .condition-title {
      display: inline;
      font-weight: 700;
      font-family: @font-family;
      color: #15161b;
      font-size: 12px;
      line-height: 16px;
    }
    .region-tag {
      margin: 3px;
      background-color: @show-more-bg;
      color: @white-color;
    }
    .condition-data {
      margin-left: 3px;
      font-weight: 400;
      font-family: @font-family;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      word-break: break-word;
    }
  }
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    font-size: 12px;
    background: @white-color;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    max-height: 500px;
    overflow-y: auto;
    word-break: break-word;
  }
  .single-item {
    background-color: @show-more-bg;
    color: @white-color;
    height: 24px;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
  }
  .content-text {
    display: flex;
    line-height: 16px;
  }
}

.industry-popover {
  .ant-popover-inner-content {
    width: 186px;
    max-width: max-content;
    word-break: break-word;

    .line-height {
      line-height: 1.5;
    }
  }
}
