@import '../../../previewComponents/globalComponents/styles/variables.less';

.my-cart-wrapper {
  margin-top: 37px;

  .title-wrapper {
    vertical-align: middle;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
  }
  .package-title-quote-variant.bold-label {
    font-size: 20px;
  }

  .sub-title {
    color: #14181c;
    margin-top: 8px;
    font-size: 14px;
  }
  .ml-16 {
    margin-left: 26px;
  }

  .panel-content {
    color: @text-primary;
    font-size: 14px;
    font-weight: 450;
  }
  .change-quote-section {
    margin: 24px 0 22px 0;
    .back-link {
      .arrow {
        margin-right: 9px;
      }
      span {
        color: @text-primary;
        font-weight: 450;
        font-size: 16px;
        word-break: break-all;
        text-decoration: underline;
      }
      :hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
    .amount-section {
      color: #14181c;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .cart-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-right: 4px;
  }

  .cart-divider.ant-divider {
    border-bottom: 1px solid @border-color;
  }

  .cart-detail-collapse.ant-collapse-borderless {
    background-color: inherit;
  }
  .cart-detail-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: unset;
  }
  .cart-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: @text-detail;
      font-weight: 500;
      display: flex;
    }
  }

  .total-amount-section {
    margin-top: 37px;
    line-height: 27px;

    .label {
      color: @text-color-secondary;
      font-size: 14px;
    }
    .amount {
      color: #14181c;
      font-weight: 700;
      font-size: 22px;
    }
  }

  .common-button-cpq {
    &.add-new-project {
      margin-top: 37px;
      border-radius: 10px;
      font-weight: 500;
      height: 44px;
      font-size: 15px;
      background: @white-color;
      color: #2f3236;
      border-color: #2f3236;
      box-shadow: none;
      width: 100%;
      overflow: hidden;
      span {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }

  .description-text {
    margin-top: 37px;
    font-size: 12px;
    color: #72788f;
  }
}
