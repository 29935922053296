@import '../../../previewComponents/globalComponents/styles/variables.less';

.project-list {
  .ant-form-item-label > label {
    flex-direction: row;
  }
}

.dimension-wrapper {
  position: relative;
  .measurement-tips-link {
    text-decoration: underline;
    margin-top: 36px;
    color: @text-primary;
    font-weight: 450;
    cursor: pointer;
  }
  .cta-button {
    margin-top: 36px;
  }

  .ant-form-item {
    &.hasDescription {
      margin-bottom: 8px;
    }
  }

  .stairs-select {
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
    }

    .ant-select-selection-placeholder {
      display: flex;
      justify-content: center;
    }
  }

  .input-description {
    margin: 8px 0px;
    color: @text-color;
    font-weight: 450;
    font-size: 12px;
  }
  .stairs-image-section {
    text-align: center;
    width: 100%;
    height: 152px;
    margin: 50px 0;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .text-center {
    text-align: center;
  }

  .map-entry-section {
    margin-bottom: 30px;
  }
}

.measurement-tips-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal.modal-cpq .ant-modal-body {
    padding: 30px;
    .measurement-tip-wrapper {
      .title {
        color: @text-primary;
        text-align: center;
        font-size: 30px;
      }
      .tips-content {
        margin-top: 36px;
      }
    }
  }
  .cta-button {
    margin-top: 36px;
  }
}

.highlight-your-area-modal {
  .ant-modal.modal-cpq .ant-modal-body {
    max-height: inherit;
    padding: 0px;
  }

  .parent-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;

    .area-image {
      width: 100%;
      object-fit: cover;
    }
    .top-section {
      width: 100%;
      top: 0px;
      left: 0px;
      padding: 0px 20px;
    }

    .bottom-section {
      width: 100%;
      bottom: 0px;
      padding: 0px 20px;
    }
    .area-show {
      color: @white-color;
      background: rgba(0, 0, 0, 0.7);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      border: 1px solid rgba(255, 255, 255, 0.18);

      .sub-title-text {
        color: @white-color;
        font-size: 16px;
        font-weight: 700;
        margin: 8px 0px 6px 0px;
      }
      .hint {
        margin: 8px 0px;
      }
      .description {
        word-break: break-all;
        text-align: center;
        p {
          margin-bottom: 8px;
        }
      }
      .mt-20 {
        margin-top: 20px;
      }

      .action-buttons {
        width: 45%;
      }

      .area-label {
        font-size: 12px;
        color: @white-color;
        font-weight: 500;
        margin-top: 8px;
      }
      .area-value {
        font-size: 21px;
        margin-bottom: 5px;
        font-weight: 500;
      }
      .area-result {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .dimension-logo-section {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
      }
      .measurement-tip-link {
        text-decoration: underline;
        margin: 10px 0px;
        font-weight: 450;
        cursor: pointer;
      }
    }
    .back-btn {
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin-bottom: 5px;
      :nth-child(2) {
        max-width: 700px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .anticon svg {
        vertical-align: middle;
      }
    }
    .cta-button {
      margin-top: 36px;
    }
  }
}
