@import '../../../previewComponents/globalComponents/styles/variables.less';

.quotes-page {
  .quote-icon {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  .tab-section {
    margin-bottom: 12px;
    .ant-carousel {
      .common-carousel .slick-prev,
      .common-carousel .slick-next,
      .common-carousel .slick-prev:hover,
      .common-carousel .slick-next:hover {
        font-size: inherit;
        color: currentColor;
      }
      .common-carousel .slick-prev,
      .common-carousel .slick-prev:hover {
        left: 10px;
        z-index: 2;
      }

      .common-carousel .slick-next,
      .common-carousel .slick-next:hover {
        right: 10px;
        z-index: 2;
      }
    }
    .label-with-icon {
      .ant-tabs-tab {
        min-height: 128px;
      }
    }
    .product-tabs {
      margin: 0 -32px;
      .ant-tabs-tab {
        color: @field-placeholder;
        font-size: 20px;
        font-weight: 600;
        height: 44px;
      }
      .ant-tabs-tab-btn:focus {
        color: @field-placeholder;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @field-color;
      }
      .ant-tabs-ink-bar {
        background: @field-color;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 24px;
      }
      .ant-tabs-nav {
        margin: 0 0 24px 0;
        .ant-tabs-nav-more {
          display: none;
        }
      }
      .ant-list-item {
        min-height: 49px;
        justify-content: flex-start;
        font-weight: 500;
        border-bottom: 1px solid @field-background-color;
      }
      .ant-tabs-content-holder {
        padding: 0 32px;
        @media screen and (max-width: 768px) {
          padding: 0 20px;
        }
      }
      .product-label {
        display: flex;
        justify-content: center;
        margin: 18px 0;
        font-size: 20px;
        color: @field-color;
        font-weight: 600;
        word-break: break-word;
        line-height: 30px;
      }
      .package-description {
        :is(body, div, ul, ol, li, h1, h2, h3, h4, h5, h6, p) {
          margin: 0;
          padding: 0;
        }
        a {
          text-decoration: underline;
        }
      }
      .price-card {
        margin-top: 20px;
        .price-section {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .price-row {
            display: flex;
            align-items: center;
            line-height: 30px;
          }
          .price-icon {
            height: 24px;
            width: 24px;
            font-size: 15px;
            margin-right: 8px;
            color: @field-color;
          }
          .price {
            font-size: 20px;
            font-weight: 700;
            color: #1d2228;
          }
          .cost-per-day {
            color: @field-placeholder;
            font-weight: 450;
            line-height: 21px;
          }
        }
      }
    }
    .compare-quote-btn {
      background-color: #e6e9f1;
      color: @black-color;
      border-radius: 10px;
      padding: 12px 24px;
      margin-bottom: 24px;
      width: 312px;
      height: 48px;
      font-size: 16px;
      border: none;
    }
    .product-image {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      img {
        height: 144px;
        object-fit: contain;
      }
    }
    .quantity {
      color: @secondary-field-color;
      font-size: 12px;
      font-weight: 500;
      margin-right: 19px;
    }
    .product-name {
      text-align: left;
      margin-bottom: 0px;
      color: @field-color;
      word-break: break-word;
      font-weight: 500;
    }
    .price-col {
      text-align: right;
    }
    .product-price {
      color: @field-color;
      word-break: break-word;
      font-weight: 500;
    }
  }
  .price-description {
    margin-top: 20px;
    color: @field-placeholder;
    font-weight: 450;
    line-height: 21px;
    .price-factor {
      margin-top: 12px;
      cursor: pointer;
      text-decoration: underline;
      color: @field-placeholder;
      word-break: break-word;
    }
  }
  .marketing-section {
    .marketing-card {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      border-radius: 8px;
      background-color: @field-background-color;
      .marketing-icon {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        font-size: 20px;
        color: @field-color;
      }
      .marketing-title {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 600;
        color: @field-color;
        word-break: break-word;
      }
      .marketing-description {
        margin-top: 16px;
        color: @secondary-field-color;
        word-break: break-word;
      }
    }
    .customer-card {
      margin-top: 32px;
    }
  }
}

.service-section {
  position: sticky;
  bottom: 0;
  z-index: 999;
  border-top: 1px solid #ededed;
  background: @white-color;
  margin-bottom: -32px;
  min-height: 150px;
  .service-card {
    padding: 16px 8px 8px;
    border-top: 1px solid #ededed;
    .countdown-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: @field-error;
      margin-bottom: 16px;
      word-break: break-word;
      .ant-statistic-content {
        font-size: 14px;
        color: @field-error;
        .ant-statistic-content-value {
          min-width: 64px;
        }
      }
      img {
        margin-right: 8px;
      }
    }
    .back-button {
      margin-top: 16px;
      padding: 0;
      height: 18px;
      box-shadow: unset;
    }
  }
}

.overlay-text.ant-popover {
  max-width: @portal-width - 2 * @padding-horizontal;
  font-size: 12px;
  color: @field-color;
  text-align: center;
  word-break: break-word;
}
