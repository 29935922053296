@import '../../../styles/variables.less';

.product-pricing-panel-component::-webkit-scrollbar {
  display: none;
}

.product-pricing-panel.side-panel-wrapper {
  .side-panel-container & {
    background-color: @white-color;
  }
}

.product-pricing-panel {
  .footer-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .product-pricing-panel-component {
    padding: 16px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    margin: 0;
    .panel-description {
      width: 90%;
      color: @ada-sidebar-color;
      font-size: 11px;
      font-weight: 500;
    }
    .panel-section {
      max-height: 500px;
      overflow-y: auto;
    }
  }
  .label {
    font-size: 11px;
    line-height: 15px;
    color: @text-color-secondary;
    margin-bottom: 4px;
  }
  .ant-input-group.ant-input-group-compact {
    display: flex;
    margin-top: 8px;
  }
  .ant-input-group-compact {
    .profit-select-width {
      width: 50px;
      min-width: unset;
    }
  }
}
