@import '../../styles/variables.less';

.input-component {
  .ant-input {
    color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: @field-font-size;
    line-height: 16px;
    border: 1px solid @border-color;
    border-radius: unset;
  }
  .ant-input::placeholder {
    color: @placeholder-color;
    font-weight: 400;
  }

  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
  .ant-input-show-count-suffix {
    color: @field-color;
    font-weight: 500;
  }
  &.ant-input-affix-wrapper:focus {
    box-shadow: none;
    border: none;
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: none;
  }
}

.price-component.input-component.ant-input-affix-wrapper {
  color: @field-color;
  font-size: @field-font-size;
  font-weight: 500;
  border: none;
}

.price-component.input-component.ant-input-affix-wrapper::placeholder {
  color: @placeholder-color;
  font-weight: 400;
}

.ant-form-item-has-error .price-component.ant-input-affix-wrapper,
.ant-form-item-has-error .input-component.ant-input-affix-wrapper:hover {
  border: 1px solid @field-error;
  background-color: @field-background-color;
}

.password-input.ant-input-affix-wrapper.ant-input-password {
  background-color: @field-background-color;
  font-weight: 500;
  font-size: @field-font-size;
  line-height: 16px;
  border: 1px solid @border-color;
  height: @field-height;
  border-radius: @field-border-radius;
  .ant-input {
    background-color: @field-background-color;
    font-size: @field-font-size;
    line-height: 16px;
    border: 1px solid @border-color;
    transition: none;
  }
  .ant-input::placeholder {
    color: @placeholder-color;
    font-weight: 400;
  }
}

.password-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid @border-color;
}

.input-component {
  &.ant-input-affix-wrapper {
    border: 1px solid @border-color;
    background-color: @field-background-color;
    border-radius: @field-border-radius;
    height: @field-height;
    padding: 8px;
    transition: none;
  }
  &.ant-input-affix-wrapper-disabled {
    background-color: @field-disabled-color;
    border: none;
    input.ant-input.ant-input-disabled {
      background-color: @field-disabled-color;
    }
  }
}

.input-component.ant-input-affix-wrapper-disabled:hover {
  border: none;
  padding: 8px;
}

.input-component.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
  height: 100%;
}
.ant-form-item-has-error {
  .input-component.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .input-component.ant-input-affix-wrapper:hover.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper-disabled,
  .input-component.ant-input-affix-wrapper-disabled:hover {
    padding: 8px;
    border: 1px solid @field-error;
    background-color: @field-disabled-color;
  }
  .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper-focused {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }

  .password-input.ant-input-affix-wrapper.ant-input-password {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border: none;
}

.input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @field-background-color;
  z-index: 1;
}

.ant-form-item-has-error {
  &.input-component.ant-input-affix-wrapper-disabled {
    background-color: @field-disabled-color;
    border: none;
    input.ant-input.ant-input-disabled {
      background-color: @field-disabled-color;
    }
  }
}
