@import '../../../../styles/variables.less';

.custom-picker.common-time-picker {
  &.ant-picker {
    width: 100%;
  }
}

.custom-picker.common-range-picker {
  &.ant-picker {
    width: 100%;
  }
}

.common-range-picker {
  &.ant-picker {
    &.ant-picker-disabled {
      background-color: @field-background-color;
    }
  }
}

.datepicker-component {
  &.ant-picker {
    &.ant-picker-disabled {
      background-color: @field-background-color;
    }
  }
}
