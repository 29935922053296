.import {
  .error-file {
    color: red;
    cursor: pointer;
  }
  .error-file:hover {
    color: red;
  }
  .link-btn {
    color: #038fde;
    cursor: pointer;
  }
}
.upload-header {
  margin-right: 16px;
  font-size: 24px;
}
