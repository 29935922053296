@import '../../styles/variables.less';
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.number-component {
  &.ant-input {
    color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: @field-font-size;
    line-height: 16px;
    height: @field-height;
    box-shadow: none;
    border: none;
    border-radius: @field-border-radius;
  }
  &.ant-input:focus {
    box-shadow: none;
  }
  .ant-input {
    color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: @field-font-size;
    line-height: 16px;
    border: 1px solid @border-color;
    border-radius: unset;
  }

  .ant-input::placeholder {
    color: @placeholder-color;
    font-weight: 400;
  }
  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
  &.ant-input-affix-wrapper:focus {
    box-shadow: none;
    border: none;
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: none;
  }
  &.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    background-color: @field-disabled-color;
    border: none;
    input.ant-input.ant-input-disabled {
      background-color: @field-disabled-color;
    }
  }
}

.ant-form-item-has-error {
  .number-component.ant-input-affix-wrapper,
  .number-component.ant-input-affix-wrapper :hover {
    background-color: @field-background-color;
  }
  .number-component.ant-input-status-error:not(.ant-input-borderless).ant-input {
    background-color: @field-background-color;
    border: 1px solid @field-error;
  }
  .number-component.ant-input-status-error:not(.ant-input-borderless).ant-input:hover {
    background-color: @field-background-color;
    border: 1px solid @field-error;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .number-component.ant-input-affix-wrapper-focused {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
  .number-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
}

.number-component {
  &.ant-input-affix-wrapper {
    border: 1px solid @border-color;
    background-color: @field-background-color;
    height: @field-height;
    border-radius: @field-border-radius;
  }
}

.number-component.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
}

.number-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @field-background-color;
  z-index: 1;
}
