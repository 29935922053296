.input-picker.input-component {
  .preview-color {
    height: 14px;
    width: 14px;
    border-radius: 3px;
  }
  .picker {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.color-picker-overlay {
  .ant-dropdown-menu {
    padding: 0;
    box-shadow: none;
  }
  .ant-dropdown-menu-item {
    padding: 0;
  }
}
