@import '../../styles/variables.less';

.dashboard-module {
  .chart-section {
    height: calc(100vh - 104px);
    overflow-y: auto;
    background-color: @white-color;
    .header-title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: @text-detail;
      text-transform: uppercase;
    }
    .funnel-section {
      padding: 24px;
      .scroll-section {
        margin-top: 16px;
        height: calc(100vh - 186px);
        overflow-y: auto;
        overflow-x: hidden;
        @media screen and (max-width: 1199px) {
          height: unset;
        }
      }
      .time-period-section {
        padding: 0px 2px;
        margin-bottom: 3px;
        .time-period-label {
          font-size: 10px;
          line-height: 16px;
          color: #adadaf;
        }
        .date-range-picker {
          margin-top: 16px;
        }
      }
      .checkbox-section {
        margin-top: 30px;
        padding: 16px;
        .single-section {
          margin-bottom: 40px;
          .title {
            font-size: 12px;
            font-weight: 400;
            color: @black-color;
            margin-bottom: 4px;
            word-break: break-word;
            line-height: 16px;
          }
          .count {
            font-size: 18px;
            font-weight: bold;
            color: @black-color;
            line-height: 22px;
          }
          .price {
            font-weight: 400;
            color: @black-color;
            margin-left: 8px;
          }
          .percentage {
            font-size: 12px;
            margin-top: 4px;
            font-weight: 400;
            color: @ada-sidebar-color;
            line-height: 15px;
          }
        }
      }
    }
    .chart-area {
      padding: 24px 24px 24px 0px;
      height: calc(100vh - 104px);
      @media screen and (max-width: 1199px) {
        padding: 24px;
      }
      .chart {
        margin-top: 30px;
        height: calc(100vh - 204px);
        @media screen and (max-width: 1199px) {
          margin-top: 0;
        }
      }
      .chart-switch {
        background-color: @primary-color;
        .ant-switch-handle {
          border-color: @field-background-color;
        }
        .ant-switch-handle::before {
          background-color: @white-color;
        }
      }
      .margin-left-space {
        color: @black-color;
        font-size: 16px;
        margin-left: 8px;
      }
      .margin-right-space {
        color: @black-color;
        font-size: 16px;
        margin-right: 8px;
      }
      .primary-icon {
        color: @primary-color;
      }
      .mr-8 {
        margin-right: 8px;
      }
    }
  }
  .skeleton-height {
    height: calc(100vh - 104px);
    display: flex;
    align-items: center;
  }
  .no-chart-data {
    height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .no-data-icon {
      font-size: 26px;
      font-weight: 500;
      color: @black-color;
      margin-bottom: 12px;
    }
    .no-data-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: @black-color;
      margin-bottom: 16px;
    }
    .no-data-description {
      font-size: 15px;
      line-height: 22px;
      width: 35%;
      color: #5f6786;
      opacity: 0.5;
    }
  }
}
