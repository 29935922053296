@import '../../../styles/variables.less';

.side-panel-open {
  .side-panel-toggle-btn-wrap {
    left: 348px;
  }
  .default-btn-position {
    left: 329px;
  }
  .side-panel-container .side-panel-wrapper {
    left: 0;
  }
  .side-panel-backdrop {
    z-index: 25;
  }
}
.side-panel-container {
  .side-panel-wrapper {
    position: absolute;
    left: -350px;
    height: 100vh;
    z-index: 998;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: 350px;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.25);
    top: 0;
    overflow: hidden;
    transition: left 200ms;
    background-color: @field-background-color;
  }
}

.side-panel-backdrop {
  z-index: -25;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.side-panel-toggle-btn-wrap {
  cursor: pointer;
  z-index: 999;
  transition: left 200ms;
  position: absolute;
  left: 0;
  top: 50vh;
}

.default-btn-position {
  left: -21.5px;
}
