@import '../../styles/variables.less';

.custom-popover {
  &.ant-popover {
    max-width: 50%;
  }
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    font-size: 12px;
    background: @white-color;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    max-height: 500px;
    overflow-y: auto;
  }
  .single-item {
    background-color: @show-more-bg;
    color: @white-color;
    font-weight: 500;
    height: 24px;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
  }
  .content-text {
    display: flex;
    line-height: 16px;
  }
}

.custom-popover.ant-popover {
  max-width: 50%;
}

.show-more-component {
  .single-item {
    background-color: @show-more-bg;
    color: @white-color;
    font-weight: 500;
    height: 24px;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    .show-more-icon {
      display: flex;
      align-items: center;
      font-size: 10px;
    }
  }
  .content-text {
    display: flex;
    line-height: 16px;
  }
  .show-more-img {
    vertical-align: baseline;
    margin-left: 2px;
  }
  .more-shadow {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  }
}
