.user-module {
}

.collapse-extra-btn {
  margin-right: 30px;
  margin-bottom: 5px;
}
.user-profile {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
  .ant-col-24.ant-form-item-label {
    padding: 0;
  }
}
