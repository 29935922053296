@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.my-account-module {
  .ant-card-body {
    padding: 24px;
  }

  .contact-divider {
    margin-top: 30px;
    font-size: 12px;
    color: @text-detail;
    font-weight: 600;
    text-transform: uppercase;
  }

  .contact-support-collapse {
    margin-top: 30px;
    max-width: 800px;
    background: @grey-3;
    .contact-support-section {
      color: @black-color;
      a {
        color: @primary-color;
        text-decoration: underline;
      }
      :hover {
        text-decoration: none;
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 40px;
    }
    .ant-collapse .ant-collapse-item > .ant-collapse-header {
      color: @black-color;
      font-weight: 500;
      text-transform: none;
      border-bottom: none;
    }
  }
}

.change-password-modal {
  .save-btn {
    background-color: @primary-color;
    color: @white-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    height: 29px;
    box-shadow: 0px 20px 30px rgba(24, 144, 255, 0.2);
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
    .anticon {
      font-size: 12px;
    }
  }

  .cancel-btn {
    margin-right: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    width: 93px;
    height: 29px;
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
    .anticon {
      font-size: 14px;
    }
    .anticon + span {
      margin-left: 5px;
    }
  }

  .icon-color {
    color: rgba(0, 0, 0, 0.25);
  }

  .input-component {
    .ant-input {
      color: @field-color;
      font-weight: 500;
      background-color: @field-background-color;
      font-size: 12px;
      line-height: 16px;
      border: none;
    }
    .ant-input::placeholder {
      color: @placeholder-color;
    }

    .ant-input:placeholder-shown {
      background-color: @field-background-color;
    }
  }

  .password-input.ant-input-affix-wrapper.ant-input-password {
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @field-background-color;
    border-radius: 6px;
    .ant-input {
      background-color: @field-background-color;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid @field-background-color;
      transition: none;
    }
  }

  .password-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @border-color;
  }
  .ant-form-item-has-error {
    .password-input.ant-input-affix-wrapper.ant-input-password {
      border: 1px solid @field-error;
      background-color: @field-background-color;
    }
  }
  .password-input.ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border: 1px solid @field-background-color;
  }

  .ant-form-item-has-error {
    .password-input.ant-input-affix-wrapper.ant-input-password {
      border: 1px solid @field-error;
      background-color: @field-background-color;
      transition: none;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    font-size: 10px;
  }

  .ant-form-item-has-error
    :not(.input-component-cpq.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border: unset;
    transition: none;
  }
}
