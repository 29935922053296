@import './variables.less';

.input-component-cpq {
  .ant-input {
    color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @border-color;
    border-radius: unset;
  }
  .ant-input::placeholder {
    color: @field-placeholder;
  }

  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
}

.price-component.input-component-cpq.ant-input-affix-wrapper {
  color: @field-color;
  font-size: 12px;
  font-weight: 500;
  border: none;
}

.price-component.input-component-cpq.ant-input-affix-wrapper::placeholder {
  color: @field-placeholder;
}

.ant-form-item-has-error .price-component.ant-input-affix-wrapper,
.ant-form-item-has-error .input-component-cpq.ant-input-affix-wrapper:hover {
  border: 1px solid @field-error;
  background-color: @field-background-color;
}

.password-input-cpq.ant-input-affix-wrapper.ant-input-password {
  background-color: @field-background-color;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid @border-color;
  border-radius: 6px;
  .ant-input {
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @border-color;
  }
}

.password-input-cpq.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid @border-color;
}

.input-component-cpq.ant-input-affix-wrapper {
  border: 1px solid @border-color;
  background-color: @field-background-color;
  border-radius: 10px;
  height: 44px;
  padding: 8px;
  transition: none;
}

.input-component-cpq.ant-input-affix-wrapper-disabled:hover {
  border: none;
  padding: 9px;
}

.input-component-cpq.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
  text-align: center;
}
.ant-form-item-has-error {
  .input-component-cpq.ant-input-affix-wrapper,
  .input-component-cpq.ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .input-component-cpq.ant-input-affix-wrapper-disabled,
  .input-component-cpq.ant-input-affix-wrapper-disabled:hover {
    padding: 9px;
    border: none;
    background-color: @field-background-color;
  }
  .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .input-component-cpq.ant-input-affix-wrapper-focused {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
  .input-component-cpq.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }

  .password-input-cpq.ant-input-affix-wrapper.ant-input-password {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
}

.input-component-cpq.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @field-background-color;
  z-index: 1;
}
