@import '../../../styles/variables.less';

.dashboard-panel-component::-webkit-scrollbar {
  display: none;
}

.dashboard-panel.side-panel-wrapper {
  .side-panel-container & {
    background-color: @white-color;
  }
}

.dashboard-panel {
  .dashboard-panel-component {
    padding: 16px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    margin: 0;
    .panel-description {
      width: 90%;
      color: @ada-sidebar-color;
      font-size: 11px;
      font-weight: 500;
    }
    .panel-section {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}
