@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.quote-viewer-module {
  .export-btn-quote {
    margin-left: 8px;
  }
  .detail-space {
    margin-top: 13px;
  }
  .pending-color {
    .ant-badge-status-text {
      color: #cc4b00;
    }
  }
  .success-color {
    color: #398712;
  }
  .common-table {
    .ant-table-row-expand-icon-cell {
      padding: 0px;
    }
    .pending-text {
      margin: 0px 7px;
      .ant-badge-status-text {
        font-size: 12px;
        font-weight: 500;
        margin-left: 15px;
      }
    }
    .success-text {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .quote-modifier-table {
    .ant-table {
      .ant-table-placeholder {
        height: calc(100vh - 629px);
      }
    }
  }

  .content-section.quote-viewer-detail {
    height: max-content;
    margin-top: 0px;
  }
  .required-row {
    .field-detail {
      .pending-text {
        margin: 0px 7px;
        .ant-badge-status-text {
          font-size: 12px;
          font-weight: 500;
          margin-left: 8px;
        }
      }
      .success-text {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 500;
      }
      .slots {
        height: auto;
      }
    }
  }
  .card-container {
    margin-top: 12px;
    .ant-tabs-card.quote-viewer-tabs {
      .ant-tabs-content {
        height: unset;
      }
    }
  }
  .pricing-condition {
    display: inline-flex;
    align-items: center;

    .condition-max-width {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .condition-region {
      height: 24px;
      margin-left: 3px;
      font-size: 12px;
      background: @white-color;
      border-radius: 5px;
      max-width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      background-color: @show-more-bg;
      color: @white-color;
    }
    .condition-region.ant-tag {
      padding-top: 2px;
    }
    .condition-title {
      font-weight: 700;
      font-family: @font-family;
      color: @text-detail-secondary;
      font-size: 12px;
      line-height: 16px;
    }
    .condition-data {
      margin-left: 3px;
      font-weight: 400;
      font-family: @font-family;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
    }
    .show-more-img {
      vertical-align: baseline;
      margin-left: 2px;
    }
    .more-shadow {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
      background-color: @show-more-bg;
      color: @white-color;
    }
    .show-more-icon {
      font-size: 10px;
    }
    .ml-5 {
      margin-left: 5px;
    }
  }
}
