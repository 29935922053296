@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.semi-height-card {
  min-height: calc((100vh - 114px) / 2);
  background-color: @layout-background;
  border: none;
  .field-detail {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    min-height: 51px;
    margin-bottom: 16px;
    .field-label {
      margin-bottom: 8px;
      color: @text-color;
      font-size: 10px;
    }
    .field-value {
      height: 32px;
      color: @text-detail;
      font-size: 12px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .field-description {
      height: unset;
    }
    .field-logo {
      object-fit: contain;
      height: 61px;
      width: 64px;
    }
  }
}

.split-screen-section {
  padding: 16px 24px;
  background-color: @layout-background;
  border: none;
}

.industry-module {
  .ant-form-item {
    p {
      color: @primary-color;
      margin-bottom: 0;
      font-weight: @font-weight-medium;
    }
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    cursor: pointer;
  }
  .alias-popover {
    .ant-tooltip-inner {
      width: 280px;
    }
  }

  .tooltip-label .ant-form-item-label > label {
    flex-direction: row;
    align-items: flex-end;
  }
  .tooltip-label .ant-form-item-label > label::before {
    order: 1;
  }

  .editor-component .ql-container.ql-snow {
    min-height: 94px;
  }
}
