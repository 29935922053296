@import '../../styles/variables.less';
.ant-select {
  &.select-width {
    font-size: @field-font-size;
    min-width: 180px;
    font-weight: 500;
    color: @field-color;
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: @field-height;
      background: @field-background-color;
      border: none;
      border-radius: @field-border-radius;
    }
    .ant-select-selection-placeholder {
      color: @placeholder-color;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: @primary-color;
      font-weight: 500;
      background-color: @white-color;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: @field-background-color;
    }
    .ant-select-item {
      font-size: @field-font-size;
      color: @text-detail-secondary;
    }
    .ant-select-dropdown {
      border-radius: @field-border-radius;
      box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    }
    .ant-select-clear {
      background-color: @field-background-color;
    }
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: none;
    }
  }
}
.ant-select {
  &.select-width {
    &.ant-select-multiple {
      .ant-select-selector {
        padding: 0 8px 0;
      }
      .ant-select-selection-item:first-child {
        margin-left: 0px;
        height: 27px;
        color: #1d2228;
        font-weight: 500;
        border: none;
        background-color: @white-color;
        border-radius: 6px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        margin: 0 4px 0;
        color: @text-color;
        background: @tag-background;
        border-color: @tag-border;
        height: 24px;
        padding: 4px 8px;
        font-weight: 400;
        border-radius: @field-border-radius;
        .ant-select-selection-item-remove {
          color: @text-color;
          font-size: 10px;
          vertical-align: middle;
          .anticon {
            color: @text-color;
            vertical-align: inherit;
          }
        }
        .ant-select-selection-item-content {
          overflow: visible;
          display: flex;
          margin-right: 2px;
          line-height: 16px;
        }
      }
    }
  }
}

.ant-select {
  &.select-width {
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        padding: 0 8px;
      }
      .ant-select-selection-search {
        left: 8px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-weight: 500;
      }
      .ant-select-selection-search-input {
        height: @field-height;
      }
    }
  }
}
.ant-form-item-has-error
  .ant-select.ant-select-status-error.select-width:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background: @field-background-color;
  background-color: @field-background-color;
  border-radius: @field-border-radius;
  border: 1px solid @field-error;
}
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open
  .ant-select-selector,
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused
  .ant-select-selector {
  border: 1px solid @field-error;
}

.ant-form-item-has-error
  .ant-select.select-width.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid @field-error;
}
